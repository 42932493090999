import React from 'react';
import {
  TextField,
  FunctionField,
  ChipField,
  Datagrid,
  usePermissions,
  Filter,
  TextInput,
  BooleanInput,
  ListController,
  ListContextProvider,
} from 'react-admin';
import { time } from '~/utils';
import { TextField as TextFieldComponent } from '~/components';
import { StatusField } from '~/components/ra';
import { ListView } from '~/layout';
import ReferenceField from '~/components/ReferenceField';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { NoPermissions } from '~/components/ra';
import { useTranslate } from 'react-admin';
import { Account } from '~/types';

// import BackgroundTaskCSVExport from '~/components/BackgroundTaskCSVExport';
// import { useListContext } from 'react-admin';

const label = (key: string) => `resources.accounts.fields.${key}`;

const listBodyStyles = (theme: any) => {
  return createStyles({
    content: {
      fontSize: 14,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      paddingTop: 16,
      paddingBottom: 16,
    },
    error: {
      color: theme.palette.error.main,
    },
  });
};

const AccountsList = (props: any) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions?.includes('account.list')) return <NoPermissions />;
  return (
    <ListController {...props}>
      {(controllerProps: any) => {
        return (
          <ListContextProvider value={controllerProps}>
            <ListView
              filters={<AccountsFilter />}
              {...controllerProps}
              pagination={controllerProps.loading || controllerProps.total === 0 ? null : undefined}
              bulkActionButtons={false}
              // actions={<div>{/* <ExportAction {...props} /> */}</div>}
            >
              <ListBody />
            </ListView>
          </ListContextProvider>
        );
      }}
    </ListController>
  );
};

// BackgroundTask export below:

// const handleFilters = (filters: any) => {
//   if (!filters) return {};
//   return filters;
// };

// const ExportAction = () => {
//   const { filterValues } = useListContext();
//   return (
//     <BackgroundTaskCSVExport type='ACCOUNTS_EXPORT' queryArguments={handleFilters(filterValues)} />
//   );
// };

const ListBody = withStyles(listBodyStyles)((props: any) => {
  const { total, loading, classes } = props;
  const translate = useTranslate();
  const t = (key: any) => translate(label(key));
  if (loading) {
    return <div className={classes.content}>{t('searchInProgress')}</div>;
  } else if (total === 0) {
    return <div className={classes.content}>{t('noResultsFound')}</div>;
  }
  return (
    <Datagrid {...props} rowClick='show' size='small' selectedIds={null}>
      <FunctionField
        label={label('number')}
        render={(record: Account) => {
          return <div className={record?.error && classes.error}>{record?.number}</div>;
        }}
      />
      <ChipField source='type' label={label('type')} />
      <TextField source='alias' label={label('alias')} sortable={false} />
      <TextField source='currency' label={label('currency')} sortable={false} />
      <FunctionField
        label={label('owner')}
        render={(record: Account) => {
          return <ReferenceField record={{ id: record.profileId, type: record.profileType }} />;
        }}
      />
      <FunctionField
        label={label('createdAt')}
        render={(record: any) => {
          const created = time(record.created);
          return (
            <TextFieldComponent compact label={created.format('LL')}>
              {created.format('LT')}
            </TextFieldComponent>
          );
        }}
      />
      <StatusField source='status' label={label('status')} sortable={false} />
    </Datagrid>
  );
});

const AccountsFilter = (props: any) => {
  const classes = useStyles();
  return (
    <Filter {...props} className={classes.form}>
      <TextInput className={classes.formControl} label={label('number')} source='number' alwaysOn />
      <BooleanInput label={label('withDeleted')} source='withDeleted' alwaysOn />
      <BooleanInput label={label('withError')} source='withError' alwaysOn />
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      '& > div:last-child': {
        width: theme.spacing(1),
      },
    },
  },
  formControl: {
    width: 200,
  },
}));

export default AccountsList;
