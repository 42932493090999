import React, { CSSProperties } from 'react';
import { time, Longdash } from '~/utils';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import { colors as MuiColors } from '@material-ui/core';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useTheme } from '@material-ui/core/styles';

import { PersonCircleIcon as TeamIcon } from '~/img';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

import { Column } from '~/components/VirtualizedList';
import { AuditLogType } from '~/types';
import { Tag } from '~/api/auditLog';
import theme from '~/theme';
import { useGetTags } from '~/hooks/auditLog';

const useStyles = makeStyles(() => ({
  TagChip: {
    height: 20,
    marginTop: -3,
    marginBottom: -3,
    '& span': {
      marginTop: -1,
    },
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  IconRow: {
    '& svg': {
      fontSize: '1rem',
      marginBottom: '-3.5px',
    },
  },
}));

const grey = '#CACACA';
interface Props {
  children: any;
  rowData: AuditLogType;
  column: Column<AuditLogType>;
  style?: CSSProperties;
}

const rowCollors: { [x: string]: any } = {
  // 'l.info': theme.palette.text.main,
  'l.warn': theme.palette.warning.main,
  'l.error': theme.palette.error.main,
};

export const makeActionColors = (theme: any) => {
  return {
    'a.create': '#65C9A7',
    'a.update': theme.palette.warning.main,
    'a.delete': '#E34035',
    // 'a.view': grey,
  };
};

// const subjects: { [x: string]: any } = {
//   's.individual': 'individual',
//   's.business': 'business',
//   's.terminal': 'terminal',
//   's.location': 'location',
//   's.employee': 'employee',
//   's.dashboardNote': 'note',
//   's.partner': 'partner',
//   's.beneficiary': 'beneficiary',
//   's.account': 'account',
//   's.remittance': 'remittance',
//   's.card': 'card',
// };

// const operations: { [x: string]: any } = {
//   'o.audit': 'audit',
//   'o.audit_block': 'audit block',
//   'o.system': 'system',
//   'o.auth': 'auth',
// };

const getColor = (tags: Tag[], colors: { [x: string]: any }) => {
  if (!tags || !tags.length) return {};
  const tag = tags.find((item) => !!colors[item]);
  const color = colors[tag as string];
  return color ? color : {};
};

interface TagChipProps extends ChipProps {
  forTable?: boolean;
}

export const TagChip = (props: TagChipProps) => {
  const { forTable, ...rest } = props;
  const classes = useStyles();
  return (
    <Chip
      {...rest}
      className={cx({ [classes.TagChip]: forTable })}
      variant='outlined'
      size='small'
    />
  );
};

const ColoredCell = (props: Props) => {
  const { rowData, children, style } = props;
  return <div style={{ ...style, color: getColor(rowData.tags, rowCollors) }}>{children}</div>;
};

interface TagsProps {
  tags: Tag[];
  className?: string;
  forTable?: boolean;
}

export const Tags = (props: TagsProps) => {
  const { tags, className, forTable = false } = props;
  const tagsQuery = useGetTags();
  const theme = useTheme();

  const actionColors: any = makeActionColors(theme);

  const color = getColor(tags, actionColors);
  const label = tags.find((item) => !!actionColors[item])?.slice(2);

  const subject = tags.find((tag) => tag.startsWith('s.'));
  const subjectLabel = subject && tagsQuery.data?.[subject];

  const operation = tags.find((tag) => tag.startsWith('o.'));
  const operationsLabel = operation && tagsQuery.data?.[operation];

  return (
    <Grid container spacing={1} wrap='nowrap' className={cx(className)}>
      {operationsLabel && (
        <Grid item>
          <TagChip
            style={{
              color: MuiColors.blue[200],
              borderColor: MuiColors.blue[200],
            }}
            label={operationsLabel}
            forTable={forTable}
          />
        </Grid>
      )}
      {subjectLabel && (
        <Grid item>
          <TagChip style={{ color: grey }} label={subjectLabel} forTable={forTable} />
        </Grid>
      )}
      {label && (
        <Grid item>
          <TagChip style={{ color, borderColor: color }} label={label} forTable={forTable} />
        </Grid>
      )}
      {!operationsLabel && !subjectLabel && !label && <Grid item>{Longdash}</Grid>}
    </Grid>
  );
};

const LabeledActionCell = (props: Props) => {
  const { children, rowData } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={1} alignItems='center' wrap='nowrap'>
      <Grid item className={classes.ellipsis}>
        {children}
      </Grid>
      <Grid item>
        <Tags tags={rowData.tags} forTable />
      </Grid>
    </Grid>
  );
};

const ActorWithIconsCell = (props: Props) => {
  const { children, rowData } = props;
  const classes = useStyles();

  const getIcon = () => {
    switch (rowData.identityType) {
      case 'DASHBOARD':
        return <TeamIcon />;
      case 'API':
        return <PersonOutlineIcon />;
      case 'OTHER':
        if (rowData.tags.find((item) => item === 's.terminal')) {
          return <SmartphoneIcon />;
        }
        // if (rowData.tags.find((item) => item === 's.location')) {
        //   return <LocationOnIcon />;
        // }
        // if (rowData.tags.find((item) => item === 's.employee')) {
        //   return <PersonPinCircleIcon />;
        // }
        // return <CloudDoneIcon />;
        return '? Other';
      default:
        return <CloudDoneIcon />;
    }
  };

  return (
    <Grid className={classes.IconRow} container spacing={1} wrap='nowrap'>
      <Grid item>
        <SvgIcon>{getIcon()}</SvgIcon>
      </Grid>
      <Grid item>{children || 'System'}</Grid>
    </Grid>
  );
};

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;

  switch (column.id) {
    case 'timestamp': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {time(children).format('LL HH:mm:ss.ms')}
        </ColoredCell>
      );
    }
    case 'profileId': {
      return (
        <ColoredCell
          column={column}
          rowData={rowData}
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {children}
        </ColoredCell>
      );
    }
    case 'action': {
      return (
        <ColoredCell style={{ width: '100%' }} column={column} rowData={rowData}>
          <LabeledActionCell column={column} rowData={rowData}>
            {children}
          </LabeledActionCell>
        </ColoredCell>
      );
    }
    case 'actor': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <ActorWithIconsCell column={column} rowData={rowData}>
            {children}
          </ActorWithIconsCell>
        </ColoredCell>
      );
    }
    default: {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children}
        </ColoredCell>
      );
    }
  }
};

export default CustomCell;
