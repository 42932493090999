import React, { CSSProperties } from 'react';
import { time } from '~/utils';

import { Column } from '~/components/VirtualizedList';
import { ChipField, PlusField, StatusField, TextField } from '~/components';
import { EventType } from '~/types/Event';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { chooseEntityWithMinIndex } from '~/resources/events';
import ReferenceField from '~/components/ReferenceField';
import theme from '~/theme';

interface Props {
  children: any;
  rowData: EventType;
  column: Column<EventType>;
  style?: CSSProperties;
}

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;
  const classes = useStyles();
  switch (column.id) {
    case 'createdAt': {
      const createdAt = time(rowData.timestamp);
      return (
        <TextField compact label={createdAt.format('LL')}>
          {createdAt.format('LTS')}
        </TextField>
      );
    }
    case 'topic': {
      return (
        <TextField ellipsis compact label={rowData.topic} className={classes.ellipsis}>
          {rowData.description}
        </TextField>
      );
    }
    case 'object':
    case 'actor': {
      const object = children?.reduce(chooseEntityWithMinIndex, undefined);
      return object ? <ReferenceField record={object} /> : null;
    }
    case 'tags':
      return (
        <PlusField
          items={rowData.tags}
          mainComponent={(tag) => <ChipField label={tag} className={classes.chip} key={tag} />}
          tooltipsComponent={(tag) => <ChipField label={tag} key={tag} />}
        />
      );
    case 'status':
      if (rowData.tags[0]) {
        const index = rowData.tags[0].indexOf('=');
        const status = rowData.tags[0].slice(index + 1);
        return <StatusField status={status} />;
      }
      return null;
    case 'processingStatuses': {
      const processingStatuses = Object.entries(rowData?.processing?.state ?? {});
      return (
        <PlusField
          items={processingStatuses}
          mainComponent={(status) => (
            <TextField compact label={status?.[0]} key={status?.[0]}>
              {status?.[1]}
            </TextField>
          )}
          tooltipsComponent={(status) => (
            <TextField
              compact
              label={status?.[0]}
              key={status?.[0]}
              className={classes.bottomSpacing}
            >
              {status?.[1]}
            </TextField>
          )}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const useStyles = makeStyles(() => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'relative',
  },
  chip: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(1),
  },
}));

export default CustomCell;
