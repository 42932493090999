import React, { FC } from 'react';
import { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';
import { isEmpty } from 'lodash';
import { makeStyles, Theme } from '@material-ui/core';
import { SelectArrayInput } from 'react-admin';
import cx from 'classnames';

interface Props {
  source: string;
  name: string;
  label: string;
  className?: string;
  alwaysOn?: boolean;
}

const roles = [
  'BUSINESS_ADMINISTRATOR',
  'BUSINESS_MANAGER',
  'BUSINESS_CASHIER',
  'BUSINESS_SALES_AGENT',
];

const EmployeeRolesFilter: FC<Props> = ({ source, name, label, alwaysOn, className }) => {
  const { data: tEmployees, isFetched: tEmployeesIsFetched } = useEmployeeRolesTranslations();
  const combinedData = roles.map((item: string) => {
    return {
      name: tEmployees && tEmployeesIsFetched && !isEmpty(tEmployees) ? tEmployees[item] : item,
      id: item,
    };
  });
  const classes = useStyles();

  return (
    <SelectArrayInput
      fullWidth
      source={source}
      name={name}
      choices={combinedData ?? []}
      label={label}
      alwaysOn={alwaysOn}
      className={cx(className, classes.input)}
      helperText={false}
      options={{
        MenuProps: {
          classes: {
            paper: classes.MenuPaper,
          },
          variant: 'menu',
          getContentAnchorEl: null,
        },
        renderValue: (selected: any) =>
          (selected?.map((item: string) =>
            tEmployees && tEmployeesIsFetched && !isEmpty(tEmployees) ? tEmployees[item] : item
          ) as string[]).join(', '),
      }}
    />
  );
};

const useStyles = makeStyles<Theme>(() => ({
  input: {
    '& > div': {
      minHeight: 44,
    },
  },
}));

export default EmployeeRolesFilter;
