import React, { Component, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { WithPermissions } from 'react-admin';

import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

// icons
// https://material.io/resources/icons/?icon=person&style=baseline
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Solutions from '@material-ui/icons/Star';
import BusinessesIcon from '@material-ui/icons/Store';
import PricingIcon from '@material-ui/icons/AttachMoney';
import DealIcon from '@material-ui/icons/Public';
import ReportsIcon from '@material-ui/icons/Assessment';
import ApiIdentitiesIcon from '@material-ui/icons/Fingerprint';
import FeedbackIcon from '@material-ui/icons/StarHalfRounded';
import BatchIcon from '@material-ui/icons/DynamicFeed';
import PaymentServicesIcon from '@material-ui/icons/Category';
import LocalizationIcon from '@material-ui/icons/Language';
import RolesIcon from '@material-ui/icons/HowToReg';
import LinkIcon from '@material-ui/icons/Link';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ConfigurationIcon from '@material-ui/icons/Widgets';
import AccountsIcon from '@material-ui/icons/Money';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {
  PersonIcon as IndividualsIcon,
  FileTrayFullIcon as AuditIcon,
  TrailSign as PartnersIcon,
  PersonCircleIcon as TeamIcon,
  ShieldCheckmarkIcon as KYCAppIcon,
  ArchiveIcon as PnLIcon,
  AlbumsIcon as BankAccountIcon,
  ReaderIcon as TransactionIcon,
  SyncCircleIcon as CurrenciesIcon,
} from '~/img';

import CircularProgress from '@material-ui/core/CircularProgress';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import { RootState } from '~/types';
import { getKycCredentials, kycAppLogin, openKycApp } from '~/api/kyc';
import onError from '~/errorsHandler';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

interface Props {
  permissions: string[];
}

type MenuItemProps = {
  path: string;
  label: string;
  icon: JSX.Element;
  disabled?: boolean;
  level?: number;
  onClick?: () => void;
};

const MenuItem = (props: MenuItemProps) => {
  const { path, label, icon, disabled, level, onClick } = props;
  const history = useHistory();
  const location = useLocation();
  const translate = useTranslate();

  return (
    <ListItem
      button
      disabled={disabled}
      onClick={(e: any) => {
        history.push(path);
        onClick && onClick();
        e.preventDefault();
      }}
      component='a'
      selected={path === '/' ? location.pathname === path : location.pathname.startsWith(path)}
      style={{ paddingLeft: level ? `${4 * level}em` : undefined }}
      href={path}
      title={translate(`layout.menu.${label}`)}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={translate(`layout.menu.${label}`)} />
    </ListItem>
  );
};

const Menu = ({ permissions }: Props) => {
  const translate = useTranslate();
  useSelector<RootState>((state) => state.theme); // force rerender on theme change

  const open = useSelector<RootState>((state) => state.admin.ui.sidebarOpen);
  useSelector<RootState>((state) => state.theme); // force rerender on theme change

  const [kycLoading, setKycLoading] = useState(false);
  const handleKycAppClick = async () => {
    setKycLoading(true);
    try {
      const {
        data: { username, password },
      } = await getKycCredentials();
      await kycAppLogin(username, password);
      openKycApp();
    } catch (error) {
      onError(error);
    }
    setKycLoading(false);
  };

  const location = useLocation();

  const [isAccountsOpen, setAccountsOpen] = useState<boolean>(
    location.pathname.startsWith('/accounts') ||
      location.pathname.startsWith('/tenant/accounts') ||
      location.pathname.startsWith('/bank/accounts')
  );
  const [isConfigurationOpen, setConfigurationOpen] = useState<boolean>(
    location.pathname.startsWith('/solutions') ||
      location.pathname.startsWith('/paymentServices') ||
      location.pathname.startsWith('/localization')
  );
  const [isCredentialsOpen, setCredentialsOpen] = useState<boolean>(
    location.pathname.startsWith('/apiIdentities') ||
      location.pathname.startsWith('/team') ||
      location.pathname.startsWith('/roles')
  );

  const classes = useStyles();

  const collapseAll = () => {
    setAccountsOpen(false);
    setConfigurationOpen(false);
    setCredentialsOpen(false);
  };

  return (
    <List style={{ width: '100%', maxWidth: 360 }} dense className={classes.container}>
      <MenuItem path='/' label='home' icon={<DashboardIcon />} onClick={collapseAll} />
      <Divider />
      <ListItem
        button
        onClick={() => {
          setAccountsOpen(!isAccountsOpen);
          setConfigurationOpen(false);
          setCredentialsOpen(false);
        }}
      >
        <ListItemIcon>
          <SvgIcon>
            <AccountsIcon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={translate(`layout.menu.accountsSubmenu`)} />
        {isAccountsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isAccountsOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding dense>
          <MenuItem
            path='/accounts'
            label='accounts'
            icon={
              <SvgIcon>
                <AccountBalanceWallet />
              </SvgIcon>
            }
            disabled={!permissions?.includes('account.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/tenant/accounts'
            label='pnlAccounts'
            icon={
              <SvgIcon>
                <PnLIcon />
              </SvgIcon>
            }
            disabled={!permissions?.includes('tenant.account.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/bank/accounts'
            label='bankAccounts'
            icon={
              <SvgIcon>
                <BankAccountIcon />
              </SvgIcon>
            }
            disabled={!permissions?.includes('bank.account.list')}
            level={open ? 1 : undefined}
          />
        </List>
      </Collapse>
      <MenuItem
        path='/headTransactions'
        label='headTransactions'
        icon={
          <SvgIcon>
            <TransactionIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('headTransaction.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/deals'
        label='deals'
        icon={<DealIcon />}
        disabled={!permissions?.includes('deal.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/actionReports'
        label='actionReports'
        icon={<ReportsIcon />}
        disabled={!permissions?.includes('report.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/events'
        label='events'
        icon={<FeedbackIcon />}
        disabled={!permissions?.includes('event.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/batches'
        label='batches'
        icon={<BatchIcon />}
        disabled={!permissions?.includes('batchPayment.list')}
        onClick={collapseAll}
      />
      <Divider />
      <MenuItem
        path='/individuals'
        label='individuals'
        icon={
          <SvgIcon>
            <IndividualsIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('individual.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/businesses'
        label='businesses'
        icon={
          <SvgIcon>
            <BusinessesIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('business.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/employees'
        label='businessTeamMembers'
        icon={
          <SvgIcon>
            <SupervisedUserCircleIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('business.employee.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/partners'
        label='partners'
        icon={
          <SvgIcon>
            <PartnersIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('partner.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/externalProfileLinks'
        label='externalProfileLinks'
        icon={<LinkIcon />}
        disabled={!permissions?.includes('externalProfileLink.list')}
        onClick={collapseAll}
      />
      <Divider />
      <ListItem button onClick={handleKycAppClick} disabled={!permissions?.includes('kyc.app')}>
        <ListItemIcon>
          {kycLoading ? (
            <CircularProgress size={24} />
          ) : (
            <SvgIcon>
              <KYCAppIcon />
            </SvgIcon>
          )}
        </ListItemIcon>
        <ListItemText primary={translate('layout.menu.kycApp')} />
      </ListItem>
      <Divider />
      <MenuItem
        path='/pricings'
        label='pricing'
        icon={
          <SvgIcon>
            <PricingIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('solution.pricing.list')}
        onClick={collapseAll}
      />
      <MenuItem
        path='/fxrates'
        label='currencyConversion'
        icon={
          <SvgIcon>
            <CurrenciesIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('fxrates.data.view')}
        onClick={collapseAll}
      />
      <Divider />
      <ListItem
        button
        onClick={() => {
          setConfigurationOpen(!isConfigurationOpen);
          setCredentialsOpen(false);
          setAccountsOpen(false);
        }}
      >
        <ListItemIcon>
          <ConfigurationIcon />
        </ListItemIcon>
        <ListItemText primary={translate(`layout.menu.configuration`)} />
        {isConfigurationOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isConfigurationOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding dense>
          <MenuItem
            path='/solutions'
            label='solutions'
            icon={<Solutions />}
            disabled={!permissions?.includes('solution.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/paymentServices'
            label='paymentServices'
            icon={<PaymentServicesIcon />}
            disabled={!permissions?.includes('paymentService.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/localization'
            label='settings.localization'
            icon={
              <SvgIcon>
                <LocalizationIcon />
              </SvgIcon>
            }
            disabled={!permissions?.includes('localization.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/profileTags'
            label='profileTags'
            icon={<LocalOfferIcon />}
            disabled={!permissions?.includes('profileTag.list')}
            level={open ? 1 : undefined}
          />
        </List>
      </Collapse>
      <ListItem
        button
        onClick={() => {
          setCredentialsOpen(!isCredentialsOpen);
          setConfigurationOpen(false);
          setAccountsOpen(false);
        }}
      >
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText primary={translate(`layout.menu.credentials`)} />
        {isCredentialsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isCredentialsOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding dense>
          <MenuItem
            path='/apiIdentities'
            label='apiIdentities'
            icon={<ApiIdentitiesIcon />}
            disabled={!permissions?.includes('apiIdentity.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/team'
            label='settings.team'
            icon={
              <SvgIcon>
                <TeamIcon />
              </SvgIcon>
            }
            disabled={!permissions?.includes('identity.list')}
            level={open ? 1 : undefined}
          />
          <MenuItem
            path='/roles'
            label='settings.roles'
            icon={<RolesIcon />}
            disabled={
              !(
                permissions?.includes('dashboardRole.create') ||
                permissions?.includes('dashboardRole.update') ||
                permissions?.includes('dashboardRole.delete')
              )
            }
            level={open ? 1 : undefined}
          />
        </List>
      </Collapse>
      <MenuItem
        path='/auditLog'
        label='settings.auditLog'
        icon={
          <SvgIcon>
            <AuditIcon />
          </SvgIcon>
        }
        disabled={!permissions?.includes('auditLog.list')}
        onClick={collapseAll}
      />
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    '& .Mui-selected .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .Mui-selected span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '& span': {
      whiteSpace: 'nowrap',
    },
  },
}));

class MenuWithPermissions extends Component {
  render() {
    const { pathname } = this.props as any;
    return (
      <WithPermissions
        render={({ permissions }: any) => {
          return <Menu permissions={permissions} {...this.props} />;
        }}
        // it's only way to make WithPermissions return the actual permissionas. We enforce state update by 'authParams' change
        // https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/auth/WithPermissions.tsx
        authParams={pathname}
      />
    );
  }
}

export default connect((state: any) => ({ pathname: state.router.location.pathname }))(
  MenuWithPermissions
);
