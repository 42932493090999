import React, { FC } from 'react';
import { Button } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';

import { OpenIcon } from '~/img';
import { useApiIdentitiesT } from '../utils';
import { Link } from '~/components';

interface Props {
  profileId: string;
  profileType: 'INDIVIDUAL' | 'BUSINESS';
  objectTypeId?: string;
  objectType?: 'EMPLOYEE' | 'TERMINAL';
}

const GoToProfile: FC<Props> = (props) => {
  const { profileId, profileType, objectType, objectTypeId } = props;
  const t = useApiIdentitiesT();

  const link =
    profileType === 'INDIVIDUAL'
      ? `/individuals/${profileId}/show`
      : profileType === 'BUSINESS'
      ? objectType === 'EMPLOYEE'
        ? `/employees/${objectTypeId}/show`
        : `/businesses/${profileId}/show`
      : undefined;

  const label =
    profileType === 'BUSINESS' && objectType === 'EMPLOYEE'
      ? t('goToTeamMember')
      : t('goToProfile');

  return (
    <Link
      href={link}
      target='_blank'
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button label={label}>
        <SvgIcon>
          <OpenIcon title='show' />
        </SvgIcon>
      </Button>
    </Link>
  );
};

export default GoToProfile;
