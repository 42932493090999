import React, { FC } from 'react';
import { useTranslate, Tab } from 'react-admin';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { TextField, StatusField, ChipField, H2 } from '~/components';
import { RibbonIcon } from '~/img';
import { Longdash, hasTranslation } from '~/utils';
import { Show, PaperTabbedShowLayout, AsideBase, Section } from '~/layout';
import { useRightContentSpacing } from '~/hooks/styles';
import { usePermissions } from 'react-admin';
import { label } from './EmployeeList';
import AffiliatedObjects from '~/components/AffiliatedObjects';
import { useShowController } from 'react-admin';
import { Employee, Business } from '~/types';
import { useRefresh } from 'react-admin';
import ReferenceField from '~/components/ReferenceField';
import GetAccessCode from './components/GetAccessCode';
import EmployeeEdit from './components/EmployeeEdit';
import { useBusinessById } from '~/hooks/businesses';
import Metrics from './components/Metrics';
import { NoPermissions, DeleteButton } from '~/components/ra';
import { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';
import BooleanField from '~/components/BooleanField';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { isEmpty } from 'lodash';

type StylesProps = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  headerSection: {
    '& .sectionActions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& .sectionActions': {
        opacity: 1,
      },
    },
  },
}));

const EmployeeShow: FC<any> = (props) => {
  // Get the record data
  const translate = useTranslate();
  const t = (key: string, args?: any): string => translate(label(key), args);
  const { permissions } = usePermissions();
  const refetch = useRefresh();

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  // Get the record data
  const showController = useShowController(props);
  const showRecord: Employee = showController.record;

  const { data: businessRecord } = useBusinessById(showRecord?.businessId);
  const { data: tEmployees } = useEmployeeRolesTranslations();

  const path = 'components.ra.fields.DocumentCard.documentType.';
  const translateField = (path: string, key: string) => {
    const fullPath = `${path}${key}`;
    return hasTranslation(fullPath) ? translate(fullPath) : key;
  };

  const isCashierOrAgent =
    showRecord?.roles?.includes('BUSINESS_CASHIER') ||
    showRecord?.roles?.includes('BUSINESS_SALES_AGENT');

  const hasUpdatePermission = permissions?.includes('business.employee.update');

  if (!showRecord) return null;
  if (!permissions) return null;
  if (!permissions.includes('business.employee.list')) return <NoPermissions />;
  return (
    <Show {...props}>
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.details')}>
          <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
            <Grid item xs={12} className={classes.headerSection}>
              <Grid container justify='space-between' alignItems='flex-start'>
                <Grid item>
                  <H2 topSpacing={0} bottomSpacing={2}>
                    {t('profileDetails')}
                  </H2>
                </Grid>
                {!showRecord?.deleted && (
                  <Grid item className='sectionActions'>
                    <Grid
                      container
                      item
                      spacing={2}
                      justify='flex-end'
                      className={classes.topSpacing}
                    >
                      {isCashierOrAgent && (
                        <Grid item>
                          <GetAccessCode employeeRecord={showRecord} isRefetchOne />
                        </Grid>
                      )}
                      <Grid item>
                        <DeleteButton
                          resource='employees'
                          target='employees'
                          targetId={showRecord?.id}
                          record={showRecord}
                          disabled={!permissions?.includes('business.employee.delete')}
                          isRefetchOne
                        />
                      </Grid>
                      <Grid item>
                        <EmployeeEdit
                          employeeRecord={showRecord}
                          businessRecord={businessRecord as Business}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Section>
                <Grid container spacing={2} direction='row'>
                  <Grid item xs={4}>
                    <TextField label={t('firstName')} className={classes.bottomSpacing}>
                      {showRecord?.firstName}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label={t('lastName')} className={classes.bottomSpacing}>
                      {showRecord?.lastName}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label={t('phoneNumber')} className={classes.bottomSpacing}>
                      {showRecord?.phoneNumber}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction='row'>
                  <Grid item xs={4}>
                    <TextField label={t('documentType')}>
                      {showRecord?.document?.type
                        ? translateField(path, showRecord?.document?.type)
                        : Longdash}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label={t('documentNumber')}>
                      {showRecord?.document?.number}
                    </TextField>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>

          <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
            <Grid item xs={12} className={classes.headerSection}>
              <Grid container spacing={2} justify='space-between' alignItems='flex-start'>
                <Grid item>
                  <H2 topSpacing={0} bottomSpacing={2}>
                    {t('authority._')}
                  </H2>
                </Grid>
              </Grid>
              <Section>
                <Grid container spacing={2} direction='row'>
                  <Grid item xs={4}>
                    <TextField className={classes.bottomSpacing} label={t('position')}>
                      {showRecord?.authority?.position}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <BooleanField
                      className={classes.bottomSpacing}
                      label={t('authority.owner')}
                      value={!!showRecord.authority?.owner}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BooleanField
                      className={classes.bottomSpacing}
                      label={t('authority.executive')}
                      value={!!showRecord.authority?.executive}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction='row'>
                  <Grid item xs={4}>
                    <TextField label={t('roles')} multiline>
                      {!isEmpty(showRecord?.roles)
                        ? showRecord?.roles?.map((item) => {
                            if (!item || !tEmployees) return <></>;
                            return <ChipField label={tEmployees[item]} />;
                          })
                        : Longdash}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    {showRecord?.reportsTo ? (
                      <ReferenceField
                        label={t('reportsTo')}
                        record={{ id: showRecord?.reportsTo, type: 'EMPLOYEE' }}
                      />
                    ) : (
                      <TextField label={t('reportsTo')}>{Longdash}</TextField>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {showRecord?.terminalId ? (
                      <ReferenceField
                        label={t('linkedToTerminal')}
                        record={{ id: showRecord?.terminalId, type: 'TERMINAL' }}
                      />
                    ) : (
                      <TextField label={t('linkedToTerminal')}>{Longdash}</TextField>
                    )}
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
          <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
            <Grid item xs={12}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {t('metrics._')}
              </H2>
              <Section>
                <Metrics employeeId={showRecord?.id} emptyMessage />
              </Section>
            </Grid>
          </Grid>
        </Tab>
      </PaperTabbedShowLayout>
      <Grid container item xs direction='column' spacing={spacing}>
        <Grid item className={classes.rightContent}>
          <AsideBase>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label={t('status')} icon={<RibbonIcon />}>
                  <StatusField status={showRecord?.deleted ? 'DELETED' : 'ACTIVE'} />
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceField
                  withDefaultIcon
                  withLink={false}
                  record={{ id: showRecord?.solutionId, type: 'SOLUTION' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  textWrap
                  label={t('email')}
                  icon={<SmartphoneIcon />}
                  style={{ overflowWrap: 'break-word', display: 'inline' }}
                >
                  {showRecord?.email}
                </TextField>
              </Grid>
            </Grid>
          </AsideBase>
        </Grid>
        <Grid item className={classes.rightContent}>
          <div className={classes.header}>
            <H2 topSpacing={0} bottomSpacing={1}>
              {t('memberOf')}
            </H2>
          </div>
          <AsideBase>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ReferenceField
                  record={{ id: showRecord?.businessId, type: 'BUSINESS' }}
                  withDefaultIcon
                />
              </Grid>
            </Grid>
          </AsideBase>
        </Grid>
        <AffiliatedObjects
          id={showRecord?.id}
          references={showRecord?.references}
          resource='employees'
          refetch={refetch}
          withAdd={hasUpdatePermission}
          withArchive={hasUpdatePermission}
          withViewAll
        />
      </Grid>
    </Show>
  );
};

export default EmployeeShow;
