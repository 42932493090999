import { stringify } from 'query-string';
import axios from './utils/axios';
import { eventLogUpdate, cleanupFormValues } from '~/utils';
import { store } from './App';
import { configurationUpdate, solutionUpdate } from '~/ducks/solutions';
import withLocalFilters from '~/utils/withLocalFilters';

export const handleFiles = (resource, json) => {
  const tasks = [];
  const scan = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (!value) {
        return;
      }
      if (value.rawFile instanceof File) {
        tasks.push({
          obj,
          key,
          file: value.rawFile,
          anonymous: value.anonymous,
        });
      } else if (Array.isArray(value)) {
        value.forEach((item, i) => {
          if (item.rawFile instanceof File) {
            tasks.push({
              arr: value,
              index: i,
              file: item.rawFile,
              anonymous: item.anonymous,
            });
          } else {
            if (typeof item === 'object') {
              scan(item);
            }
          }
        });
      } else if (typeof value === 'object') {
        scan(value);
      }
    });
  };
  scan(json);

  const upload = (task) => {
    const formData = new FormData();
    formData.append('data', task.file);
    if (resource === 'businesses' || resource === 'individuals') {
      formData.append('profileId', json.id);
      formData.append('isPublic', true);
    }
    if (resource === 'locations') {
      formData.append('isPublic', true);
    }
    if (task.anonymous) {
      formData.append('anonymous', true);
    }
    return axios({
      method: 'post',
      url: resource.startsWith('paymentServices') ? '/paymentServices/icons' : '/files',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (task.arr) {
          task.arr[task.index] = {
            id: response.data.id,
            name: task.file.name,
            mimeType: task.file.type || task.file.mimeType,
            size: task.file.size,
          };
        } else if (task.obj) {
          task.obj[task.key] = {
            id: response.data.id,
            name: task.file.name,
            mimeType: task.file.type || task.file.mimeType,
            size: task.file.size,
          };
        }
      })
      .catch(() => {
        // TODO add logging
        task.obj[task.key] = null;
      });
  };

  const uploadPromise = Promise.all(
    tasks.map((task) => {
      return upload(task);
    })
  );

  return uploadPromise;
};

const withFiles = (resource, params) => {
  return (f) => {
    if (params.data) {
      console.log(params.data);
      return handleFiles(resource, params.data).then(() => {
        return f();
      });
    } else {
      return f();
    }
  };
};

const addMimeTypeToPaymentIcon = (resource) => (item) => {
  if (
    (resource === 'paymentServices' || resource === 'paymentServices/groups') &&
    typeof item.branding?.icon === 'string'
  ) {
    return {
      ...item,
      ...{
        branding: {
          color: item.branding.color,
          icon: {
            id: item.branding.icon,
            mimeType: 'image',
          },
        },
      },
    };
  }
  return item;
};

const assignId = (item) => {
  if (!item.id && item.key) {
    return Object.assign(item, { id: item.key });
  } else {
    return item;
  }
};

const dataProvider = {
  getList: withLocalFilters(async (resource, params) => {
    // do not query individuals if filter is not set
    if (resource === 'individuals' && params.filter) {
      const { phoneNumber } = params.filter;
      const extendedSearch = params.filter.extendedSearch;
      if (
        !extendedSearch &&
        (Object.keys(params.filter).length === 0 || (phoneNumber && phoneNumber.length <= 6))
      ) {
        return Promise.resolve({ data: [], total: 0, noFilter: true });
      }
    }

    const { page, perPage } = params.pagination;

    const query = {
      page,
      perPage,
      ...(params.filter || {}),
    };

    let url = '';
    if (query.solutionId && resource !== 'fxrates/mappings') {
      const { solutionId } = query;
      delete query.solutionId;
      url = `/solutions/${solutionId}/${resource}?${stringify(query)}`;
    } else if (query.businessId && resource !== 'fxrates/mappings' && resource !== 'employees') {
      const { businessId } = query;
      delete query.businessId;
      url = `/businesses/${businessId}/${resource}?${stringify(query)}`;
    } else {
      url = `/${resource}?${stringify(query)}`;
    }

    const response = await axios({ method: 'get', url });
    if (!response.headers['content-range']) {
      if (resource !== 'fxrates' && resource !== 'accounts' && resource !== 'beneficiaries') {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
        );
      }
    }
    return {
      data: response.data.map(assignId).map(addMimeTypeToPaymentIcon(resource)),
      total: response.headers['content-range']
        ? parseInt(response.headers['content-range'].split('/').pop(), 10)
        : 9999,
    };
  }),
  getOne: async (resource, params) => {
    const url = `/${resource}/${params.id}`;
    const response = await axios({ method: 'get', url });
    return {
      data: addMimeTypeToPaymentIcon(resource)(response.data),
    };
  },
  getMany: withLocalFilters(async (resource, params) => {
    const query = {
      ids: params.ids,
      ...(params.filter || {}),
    };
    let url = '';
    if (query.businessId && resource !== 'employees') {
      const { businessId } = query;
      delete query.businessId;
      url = `/businesses/${businessId}/${resource}?${stringify(query)}`;
    } else {
      url = `/${resource}?${stringify(query)}`;
    }
    const response = await axios({ method: 'get', url });
    return {
      data: response.data.map(assignId).map(addMimeTypeToPaymentIcon(resource)),
    };
  }),
  getManyReference: withLocalFilters(async (resource, params) => {
    const {
      filter = {},
      pagination: { page, perPage },
    } = params;
    const query = { page, perPage, ...cleanupFormValues(filter) };
    let url = `/${params.target}/${params.id}/${resource}?${stringify(query)}`;
    if (resource === 'fxrates/mappings') {
      url = `/${resource}?solutionId=${params.id}&${stringify(query)}`;
    }

    const response = await axios({ method: 'get', url });
    if (!response.headers['content-range']) {
      throw new Error(
        'The Content-Range header is missing in the HTTP Response. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
      );
    }
    return {
      data: response.data.map(assignId).map(addMimeTypeToPaymentIcon(resource)),
      total: parseInt(response.headers['content-range'].split('/').pop(), 10),
    };
  }),
  create: (resource, params) => {
    return withFiles(
      resource,
      params
    )(async () => {
      const url =
        (resource === 'accounts' || resource === 'employees') && params.subresource === 'references'
          ? `/${resource}/${params.id}/${params.subresource}`
          : `/${resource}`;
      if (resource === 'businesses') {
        params.data.email = params.data.employee?.email;
      }
      if (resource === 'paymentServices') {
        [
          'parameterGroups',
          'parameters',
          'amount',
          'senderAmount',
          'deal',
          'i18n',
          'integration',
          'tags',
          'additionalProperties',
          'recipientExternalSource',
        ].forEach((key) => {
          if (typeof params.data[key] === 'string') {
            params.data[key] = JSON.parse(params.data[key]);
          }
        });
      }
      if (resource === 'pricings') {
        delete params.data.paymentInstrument;
      }
      const response = await axios.post(url, params.data);
      eventLogUpdate(resource);
      return { data: { ...params.data, ...response.data } };
    });
  },
  update: (resource, params) => {
    return withFiles(
      resource,
      params
    )(async () => {
      if (resource === 'externalSources' && params.subresource === 'names') {
        const name = params.data.name;
        const alias = params.data.alias;
        params.data = { name: name ?? '', alias: alias };
      }
      if (resource === 'accounts' && params.subresource === 'alias') {
        const alias = params.data.alias;
        params.data = { alias: alias ?? '' };
      }
      const { data, id, subresource, HTTPMethod = 'PUT' } = params;
      const url = `/${resource}/${id}${subresource ? `/${subresource}` : ''}`;
      const headers = {};
      if (typeof data === 'string') headers['Content-Type'] = 'text/plain';

      if (resource === 'paymentServices') {
        [
          'parameterGroups',
          'parameters',
          'amount',
          'senderAmount',
          'deal',
          'i18n',
          'integration',
          'tags',
          'additionalProperties',
          'recipientExternalSource',
        ].forEach((key) => {
          if (typeof params.data[key] === 'string') {
            params.data[key] = JSON.parse(params.data[key]);
          }
        });
      } else if (resource === 'externalProfileLinks') {
        ['identificationDetails'].forEach((key) => {
          if (typeof params.data[key] === 'string') {
            params.data[key] = JSON.parse(params.data[key]);
          }
        });
      }

      if (resource === 'pricings') {
        const paymentInstrument = params.data.paymentInstrument;
        if (paymentInstrument === 'product') {
          delete params.data.externalSourceType;
        }
        if (paymentInstrument === 'externalSourceType') {
          delete params.data.productId;
        }
        delete params.data.paymentInstrument;
      }

      let brandingIcon = undefined;
      if (resource.startsWith('paymentServices') && params.data.branding?.icon) {
        brandingIcon = params.data.branding.icon;
        params.data.branding.icon = params.data.branding.icon.id;
      }

      let response = {};
      if (HTTPMethod === 'PUT') {
        response = await axios.put(url, data, { headers });
      } else if (HTTPMethod === 'POST') {
        response = await axios.post(url, data, { headers });
      }
      eventLogUpdate(resource);
      if (resource === 'solutions') {
        if (subresource === 'configuration') {
          store.dispatch(
            configurationUpdate({
              solutionId: id,
              data,
            })
          );
        } else if (!subresource) {
          store.dispatch(
            solutionUpdate({
              solutionId: id,
              data,
            })
          );
        }
      }
      return {
        data: {
          id: '',
          ...data,
          ...response.data,
          ...(brandingIcon
            ? { branding: { color: response.data.branding.color, icon: brandingIcon } }
            : {}),
        },
      };
    });
  },
  updateMany: () => Promise,
  delete: async (resource, params) => {
    const url = `/${resource}/${params.id}`;
    await axios.delete(url);
    eventLogUpdate(resource);
    return { data: { id: params.id } };
  },
  deleteMany: async (resource, params) => {
    const query = { uid: params.ids };
    const url = `/${resource}?${stringify(query)}`;
    await axios.delete(url);
    eventLogUpdate(resource);
    return { data: params.ids };
  },
  // custom
  getResource: async (resource, params) => {
    const parameters = params || resource;
    const url = `/${parameters.url}`;
    const response = await axios.get(url);
    return { data: response.data };
  },
  getSolutionConfigurationSchema: async (resource, params) => {
    const url = `/solutions/${params.id}/${params.resource}/schema`;
    const response = await axios.get(url);
    return { data: { configurationSchema: response.data } };
  },
  getSolutionHandbook: async (resource, params) => {
    const url = `/solutions/${params.solutionId}/handbook`;
    const response = await axios.get(url);
    return { data: response.data };
  },
  updateSolutionHandbook: async (resource, params) => {
    const url = `/solutions/${params.solutionId}/handbook`;
    await axios.put(url, params.handbook);
    return { data: params.handbook };
  },
};

export default dataProvider;
