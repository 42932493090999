import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { useRightContentSpacing } from '~/hooks/styles';
import { AsideBase } from '~/layout';
import { H2 } from '~/components';
import { useTranslate } from 'react-admin';
import ReferenceField from '~/components/ReferenceField';

type StylesProps = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
}));

interface Props {
  record: any;
  header?: string;
}

const Originator = (props: Props) => {
  const { record, header } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`components.${key}`);
  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });
  return (
    <Grid item className={classes.rightContent}>
      <H2 topSpacing={0} bottomSpacing={2}>
        {header ? header : t('Originator._')}
      </H2>
      <AsideBase>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReferenceField
              record={{
                id: record.profileId,
                type: record.profileType,
              }}
              withDefaultIcon
            />
          </Grid>
        </Grid>
      </AsideBase>
    </Grid>
  );
};

export default Originator;
