import { PASSWORD_MIN_LENGTH } from '~/components/PasswordStrengthChecklist';

export default {
  menu: {
    home: 'Home',
    accountsSubmenu: 'Accounts',
    accounts: 'All accounts',
    configuration: 'Configuration',
    credentials: 'Credentials',
    tenants: {
      submenu: 'Tenants',
    },
    individuals: 'Individuals',
    businesses: 'Businesses',
    businessTeamMembers: 'Business team members',
    pricing: 'Pricing',
    partners: 'Partners',
    deals: 'Deals',
    security: {
      submenu: 'Security',
      pendingIndividualProfiles: 'Pending Individual Profiles',
      pendingBusinessProfiles: 'Pending Business Profiles',
      auditLog: 'Audit Log',
    },
    solutions: 'Solutions',
    settings: {
      submenu: 'Settings',
      auditLog: 'System log',
      team: 'Dashboard identities',
      roles: 'Dashboard roles',
      localization: 'Message templates',
    },
    kycApp: 'KYC',
    pnlAccounts: "Tenant's Accounts",
    bankAccounts: 'Bank accounts',
    transactions: 'Transactions',
    reports: 'Reports',
    currencyConversion: 'Currency conversion',
    headTransactions: 'Transactions',
    actionReports: 'Action reporting',
    feedback: 'Feedback',
    events: 'Events',
    batches: 'Batches',
    apiIdentities: 'End-user indentities',
    paymentServices: 'Payment services',
    externalProfileLinks: 'Profile links',
    profileTags: 'Profile tags',
  },
  ChangePasswordModal: {
    _: 'Change password',
    oldPassword: 'Old password',
    password: 'Password',
    passwordAgain: 'Password again',
    applyChanges: 'Apply changes',
    notMatch: 'Password mismatch',
    required: 'Required',
    length: `Password must be at least ${PASSWORD_MIN_LENGTH} characters`,
    mustContainLetters: 'Password must contains letters',
    mustContainDigits: 'Password must contains digits',
    success: 'Password changed',
    PARAMETER_INVALID: 'Provided data are incorrect',
  },
  login: {
    privacy: 'Privacy Policy',
    singInTo: 'Sing in to the',
    getDemoAccess: "Don't have an account yet? Get demo access.",
    dontReceiveTheOtp: 'Did not receive the code?',
    resendOtp: 'Resend SMS',
    enterCode: 'Enter code',
    weHaveSentCode: 'We have sent you SMS with verification code (OTP) on',
  },
};
