import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { useApiIdentityByTerminalId } from '~/hooks/apiIdentity';
import ReferenceField from '../../../components/ReferenceField';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { TextField } from '~/components';
import { Longdash } from '~/utils';

interface ApiIdentityLinkProps {
  id: string;
}

const ApiIdentityLink: FC<ApiIdentityLinkProps> = (props) => {
  const { id } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`components.ApiIdentityLink.${key}`);
  const { data: apiIdentities, isLoading } = useApiIdentityByTerminalId(id);
  if (isLoading) return <Skeleton width='100%' />;
  if (!apiIdentities || isEmpty(apiIdentities))
    return <TextField label={t('apiIdentity')}>{Longdash}</TextField>;
  return (
    <ReferenceField
      label={t('apiIdentity')}
      record={{ id: apiIdentities[0]?.id, type: 'API_IDENTITY' }}
    />
  );
};

export default ApiIdentityLink;
