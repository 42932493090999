import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { Account, Limit } from '~/types';

export const getAccounts = (
  resource: 'businesses' | 'individuals' | 'partners' | 'tenant/accounts',
  profileId: string
) => {
  return axios
    .get<Account[]>(
      (resource === 'tenant/accounts' ? `tenant/accounts` : `${resource}/${profileId}/accounts`) +
        '?page=1&perPage=999'
    )
    .then((res) => res.data);
};

export const blockAccount = (accountId: string) => {
  return axios.post(`/accounts/${accountId}/block`);
};

export const unblockAccount = (accountId: string) => {
  return axios.post(`/accounts/${accountId}/unblock`);
};

export const closeAccount = (accountId: string) => {
  return axios.post(`/accounts/${accountId}/close`);
};

export const archiveAffiliatedObject = (
  resource: string,
  resourceId: string,
  referenceId: string
) => {
  return axios.delete(`/${resource}/${resourceId}/references/${referenceId}`);
};

export const getAccountsLimits = (accountId: string) => {
  return axios.get<Limit[]>(`/accounts/${accountId}/limits`).then((res) => res.data);
};

export const getProfileLimits = (profileId: string) => {
  return axios.get<Limit[]>(`/profiles/${profileId}/limits`).then((res) => res.data);
};

interface getBankDaySummaryProps {
  id: string;
  next?: string | null;
  filters?: { [x: string]: any };
}

export type BankDaySummaryType = {
  accountId: string;
  code: string;
  date: string;
  amount: number;
  count: number;
};

export const getBankDaySummary = (props: getBankDaySummaryProps) => {
  const { id, filters, next } = props;
  const resultURL = `tenant/accounts/${id}/summary`;

  return axios.get<BankDaySummaryType[]>(
    urlWithParams(resultURL, { next, ...filters }, 'YYYY-MM-DD')
  );
};

export const resetAccountError = (props: { accountId: string; comment: string }) => {
  const { accountId, comment } = props;
  return axios.post(`accounts/${accountId}/resetError`, { comment });
};
