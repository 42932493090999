import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import { AccordionDetails, AccordionSummary, Confirmation, H3, TextField } from '~/components';
import CopyButton from '~/components/CopyButton';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, Button } from '@material-ui/core';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CancelIcon from '@material-ui/icons/Cancel';
import { useGetApiKeyInfo } from '~/hooks/terminal';
import { useMutation, useQueryClient } from 'react-query';
import { issueApiKey, revokeApiKey } from '~/api/terminal';
import time from '~/utils/time';
import useCrudGetManyReference from '~/hooks/useCrudGetManyReference';

interface IntegrationProps {
  terminalId: string;
  businessId: string;
}

const Integration: FC<IntegrationProps> = (props) => {
  const { terminalId, businessId } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.terminals.${key}`);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const crudGetManyReference = useCrudGetManyReference('terminals', 'businesses', businessId);

  const [isExpanded, setIsExpanded] = useState(true);
  const [isAlertOn, setIsAlertOn] = useState<boolean>(false);
  const [integrationData, setIntegrationData] = useState({
    tokenPrefix: '',
    createdAt: '',
    value: '',
  });

  const { data: apiKeyData, isLoading: apiKeyDataLoading } = useGetApiKeyInfo(terminalId);

  const mutationToIssue = useMutation(issueApiKey, {
    onSuccess: (data) => {
      setIntegrationData({
        tokenPrefix: data?.tokenPrefix,
        createdAt: data?.createdAt,
        value: data?.value || '',
      });
      queryClient.invalidateQueries(['apiKeyInfo', terminalId]);
      crudGetManyReference();
      setIsAlertOn(true);
    },
  });

  const mutationToRevoke = useMutation(revokeApiKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(['apiKeyInfo', terminalId]);
      setIntegrationData({
        tokenPrefix: '',
        createdAt: '',
        value: '',
      });
      crudGetManyReference();
      setIsAlertOn(false);
    },
  });

  const handleRevokeApiKey = async () => {
    await mutationToRevoke.mutateAsync(terminalId);
  };
  const handleIssueApiKey = async () => {
    await mutationToIssue.mutateAsync(terminalId);
  };
  const handleAlertClose = () => {
    setIsAlertOn(false);
  };

  const tokenPrefix = integrationData?.tokenPrefix || apiKeyData?.tokenPrefix;
  const createdAt =
    (integrationData?.createdAt && time(integrationData?.createdAt).format('LL LT')) ||
    (apiKeyData?.createdAt && time(apiKeyData?.createdAt).format('LL LT'));
  const isRevokeAvailable =
    (integrationData?.tokenPrefix && integrationData?.createdAt) ||
    (apiKeyData?.tokenPrefix && apiKeyData?.createdAt);
  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded((prev) => !prev)}>
      <AccordionSummary
        heading={t('integration')}
        aria-controls={'panel-content'}
        id={'panel-header'}
        expanded={isExpanded}
      />
      <AccordionDetails className={classes.AccordionDetails}>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <H3 topSpacing={0} bottomSpacing={0}>
              {t('apiKey')}
            </H3>
          </Grid>
          <Grid item>
            <TextField label={t('tokenPrefix')}>
              {apiKeyDataLoading || mutationToIssue.isLoading ? <Skeleton /> : tokenPrefix}
            </TextField>
          </Grid>
          <Grid item>
            <TextField label={t('dateOfCreation')}>
              {apiKeyDataLoading || mutationToIssue.isLoading ? <Skeleton /> : createdAt}
            </TextField>
          </Grid>
          <Grid item>
            <Confirmation
              confirmationSettings={{
                variant: 'modal',
                modal: {
                  heading: t('warning'),
                  content: (
                    <>
                      {isRevokeAvailable && <p>{t('reissueWarningContent')}</p>}
                      <p>
                        {isRevokeAvailable ? t('reissueWarningContent1') : t('issueWarningContent')}
                      </p>
                      <ul>
                        <li>
                          {isRevokeAvailable
                            ? t('reissueWarningContent2')
                            : t('issueWarningContent1')}
                        </li>
                        <li>
                          {isRevokeAvailable
                            ? t('reissueWarningContent3')
                            : t('issueWarningContent2')}
                        </li>
                        {isRevokeAvailable && <li>{t('reissueWarningContent4')}</li>}
                      </ul>
                      <p>
                        {isRevokeAvailable
                          ? t('reissueWarningContent5')
                          : t('issueWarningContent3')}
                      </p>
                    </>
                  ),
                },
              }}
              onConfirm={() => {
                handleIssueApiKey();
              }}
            >
              <Button color='primary' startIcon={<FlashOnIcon />} variant='contained'>
                {t('issueApiKey')}
              </Button>
            </Confirmation>
            {isRevokeAvailable && (
              <Confirmation
                confirmationSettings={{
                  variant: 'modal',
                  modal: {
                    heading: t('warning'),
                    content: t('revokeWarningContent'),
                  },
                }}
                onConfirm={() => {
                  handleRevokeApiKey();
                }}
              >
                <Button
                  onClick={handleRevokeApiKey}
                  color='secondary'
                  startIcon={<CancelIcon />}
                  className={classes.revokeButton}
                >
                  {t('revokeApiKey')}
                </Button>
              </Confirmation>
            )}
          </Grid>
          {isAlertOn && (
            <Grid item>
              <Alert severity='success' onClose={handleAlertClose}>
                <AlertTitle>{t('yourApiKeyWarning')}</AlertTitle>
                <Typography className={classes.apiKey}>{integrationData?.value}</Typography>
                <CopyButton value={integrationData?.value} label={t('copyToClipboard')} />
              </Alert>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingLeft: theme.spacing(2, '!important'),
  },
  apiKey: {
    marginBottom: 8,
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
  revokeButton: {
    color: theme.palette.error.main,
    marginLeft: 16,
  },
}));

export default Integration;
