import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Drawer, TextField, PhoneField, H3, ChipField } from '~/components';
import { DeleteButton, TextField as CustomRaTextField } from '~/components/ra';
import { Business, Employee, Terminal, Location } from '~/types';
import BooleanField from '~/components/BooleanField';
import GetAccessCode from './GetAccessCode';
import EmployeeEdit from './EmployeeEdit';
import TerminalShow from '~/resources/terminals/TerminalShow';
import { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';
import LocationShow from '../../locations/LocationShow';
import { ReferenceArrayField } from 'react-admin';
import { SingleFieldList } from 'react-admin';
import { isEmpty } from 'lodash';
import ViewDetailsButton from './ViewDetailsButton';
import Metrics from './Metrics';
import CopyButton from '~/components/CopyButton';
import { label } from '../EmployeeList';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  topSpacing: {
    marginTop: theme.spacing(1),
  },
  locations: {
    flexDirection: 'column',
    '& > div': {
      marginBottom: theme.spacing(1),
    },
  },
  locationsHeader: {
    fontSize: '10px',
    fontWeight: 'normal',
    marginBottom: '4px',
  },
  uuid: {
    color: theme.palette.grey[500],
  },
  // For copy button
  button: {
    padding: 0,
    color: theme.palette.grey[500],
    marginLeft: 10,
  },
  icon: {
    fontSize: '1rem',
  },
}));

interface Props {
  showData: Employee | null;
  onClose: () => void;
  businessRecord: Business;
  parentProps: any;
  permissions: string[];
}

const EmployeeDrawer = (props: Props) => {
  const { showData, onClose, businessRecord, parentProps, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  const { data: tEmployees } = useEmployeeRolesTranslations();
  const classes = useStyles();
  const isCashierOrAgent =
    showData?.roles?.includes('BUSINESS_CASHIER') ||
    showData?.roles?.includes('BUSINESS_SALES_AGENT');
  return (
    <Drawer
      heading={`${showData?.firstName || ''} ${showData?.lastName || ''}`}
      open={!!showData}
      onClose={onClose}
    >
      {showData?.id && (
        <div className={cx(classes.bottomSpacing, classes.uuid)}>
          {showData?.id}
          <CopyButton value={showData?.id} classes={classes} />
        </div>
      )}
      {showData && (
        <Fragment>
          <div className={classes.bottomSpacing}>
            <TextField label={t('firstName')} icon={<PersonIcon />}>
              {showData.firstName}
            </TextField>
          </div>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <TextField label={t('lastName')}>{showData.lastName}</TextField>
            </Grid>
            <Grid item>
              <TextField label={t('phoneNumber')}>
                <PhoneField number={showData.phoneNumber} />
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('email')}>{showData.email}</TextField>
            </Grid>
          </Grid>
          <H3 icon={<InsertDriveFileIcon />}>{t('document._')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            {showData?.document && Object.keys(showData?.document).length !== 0 ? (
              Object.entries(showData?.document).map(([name, value], index) => (
                <Grid key={index} item>
                  <TextField label={translate(`components.ra.fields.DocumentCard.${name}`)}>
                    {name === 'countryOfIssue' ? translate(`countries.${value}`) : value}
                  </TextField>
                </Grid>
              ))
            ) : (
              <Grid item>{translate('resources.individuals.fields.noDocuments')}</Grid>
            )}
          </Grid>
          <H3 icon={<FingerprintIcon />}>{t('authority._')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <TextField label={t('authority.employeeRole._')} multiline>
                {showData?.roles?.map((item) => {
                  if (!item || !tEmployees) return <></>;
                  return <ChipField label={tEmployees[item]} />;
                })}
              </TextField>
            </Grid>
            {(showData.roles?.includes('BUSINESS_CASHIER') ||
              showData.roles?.includes('BUSINESS_SALES_AGENT')) && (
              <Grid item>
                {showData.workLocationId && !isEmpty(showData.workLocationId) ? (
                  <ReferenceField
                    {...parentProps}
                    record={showData}
                    source='workLocationId'
                    reference='locations'
                    link={false}
                  >
                    <CustomRaTextField
                      label={t('cashierAgentAt')}
                      source='name'
                      showComponent={(props) => {
                        const { open, handleClose } = props;
                        const locationRecord: Location = props.record;
                        return (
                          <LocationShow
                            permissions={permissions}
                            showData={open ? locationRecord : null}
                            businessRecord={businessRecord}
                            onClose={handleClose}
                          />
                        );
                      }}
                    />
                  </ReferenceField>
                ) : (
                  <TextField label={t('cashierAgentAt')} />
                )}
              </Grid>
            )}
            {showData.roles?.includes('BUSINESS_MANAGER') && (
              <Grid item>
                {showData.managedLocationIds && !isEmpty(showData.managedLocationIds) ? (
                  <>
                    <div className={classes.locationsHeader}>{t('managesLocations')}</div>
                    <ReferenceArrayField
                      {...parentProps}
                      record={showData}
                      source='managedLocationIds'
                      label={t('managesLocations')}
                      reference='locations'
                      className={classes.locations}
                    >
                      <SingleFieldList linkType={false}>
                        <CustomRaTextField
                          label={' '}
                          source='name'
                          showComponent={(props) => {
                            const { open, handleClose } = props;
                            const locationRecord: Location = props.record;
                            return (
                              <LocationShow
                                permissions={permissions}
                                showData={open ? locationRecord : null}
                                businessRecord={businessRecord}
                                onClose={handleClose}
                              />
                            );
                          }}
                        />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  </>
                ) : (
                  <TextField label={t('managesLocations')} />
                )}
              </Grid>
            )}
            <Grid item>
              <TextField label={t('authority.position')} textWrap>
                {showData.authority?.position}
              </TextField>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <BooleanField label={t('authority.owner')} value={!!showData.authority?.owner} />
              </Grid>
              <Grid item xs={6}>
                <BooleanField
                  label={t('authority.executive')}
                  value={!!showData.authority?.executive}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='column' spacing={2} className={classes.topSpacing}>
            <Grid item>
              <ReferenceField
                {...parentProps}
                record={showData}
                source='reportsTo'
                label={t('reportsTo')}
                reference='employees'
                link={false}
              >
                <CustomRaTextField
                  label={t('reportsTo')}
                  fn={(e: Employee) => `${e.firstName} ${e.lastName}`}
                  icon={<PersonIcon />}
                  showComponent={(props) => {
                    const { open, handleClose } = props;
                    const employeeRecord: Employee = props.record;
                    return (
                      <EmployeeDrawer
                        permissions={permissions}
                        showData={open ? employeeRecord : null}
                        businessRecord={businessRecord}
                        onClose={handleClose}
                        parentProps={parentProps}
                      />
                    );
                  }}
                />
              </ReferenceField>
            </Grid>
          </Grid>
          <Grid
            container
            direction='column'
            spacing={2}
            className={cx(classes.topSpacing, classes.bottomSpacing)}
          >
            <Grid item>
              <ReferenceField
                {...parentProps}
                record={showData}
                source='terminalId'
                label={t('linkedTerminals')}
                reference='terminals'
                link={false}
              >
                <CustomRaTextField
                  label={t('linkedTerminals')}
                  source='label'
                  icon={<SmartphoneIcon />}
                  showComponent={(props) => {
                    const { open, handleClose } = props;
                    const terminalRecord: Terminal = props.record;
                    return (
                      <TerminalShow
                        permissions={permissions}
                        showData={open ? terminalRecord : null}
                        businessRecord={businessRecord}
                        onClose={handleClose}
                        parentProps={parentProps}
                      />
                    );
                  }}
                />
              </ReferenceField>
            </Grid>
          </Grid>
          <Metrics employeeId={showData.id} />
          <Grid container item spacing={2} justify='flex-end' className={classes.topSpacing}>
            {isCashierOrAgent && (
              <Grid item>
                <GetAccessCode employeeRecord={showData} />
              </Grid>
            )}
            <Grid item>
              <ViewDetailsButton employeeRecord={showData} />
            </Grid>
            <Grid item>
              <DeleteButton
                resource='employees'
                target='businesses'
                targetId={businessRecord.id}
                record={showData}
                disabled={!permissions.includes('business.employee.delete')}
                onClose={onClose}
              />
            </Grid>
            <Grid item>
              <EmployeeEdit
                employeeRecord={showData}
                businessRecord={businessRecord}
                closeParent={onClose}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Drawer>
  );
};

export default EmployeeDrawer;
