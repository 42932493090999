import React, { FC } from 'react';
import { ObjectType } from '~/types/ObjectType';
import { TextField } from '~/components';
import { Skeleton } from '@material-ui/lab';
import { useTranslate } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { buildProfileLink, findParent, getName } from '~/utils/identities';
import BusinessIcon from '@material-ui/icons/Store';

import { ArchiveIcon as PnLIcon } from '~/img';
import {
  PersonCircleIcon as IndividualIcon,
  PersonCircleIcon as EmployeeIcon,
  TrailSign,
} from '~/img';
import StarIcon from '@material-ui/icons/Star';
import { useReferenceEntity } from '~/hooks';
import cx from 'classnames';
import { Entity } from '~/types/Entity';

interface Ref {
  id: string;
  type: ObjectType;
}

interface Props {
  label?: string;
  record: Ref;
  parent?: Ref;
  withDefaultIcon?: boolean;
  className?: string;
  withLabel?: boolean;
  withLink?: boolean;
  renderer?: (entity: Entity | undefined) => any;
}

const ReferenceField: FC<Props> = (props) => {
  const {
    record,
    parent,
    label,
    withDefaultIcon = false,
    className,
    withLabel = true,
    withLink = true,
    renderer,
  } = props;
  const { id, type } = record;

  const translate = useTranslate();

  const classes = useStyles();

  const { data: entity, isLoading, partnerId } = useReferenceEntity(record);

  // if there is no parent in props, we try to find it in entity response
  const { id: parentId, type: parentType } = parent ?? findParent(type, entity) ?? {};

  if (isLoading) return <Skeleton width='100%' height='18px' />;

  const t = (key: string): string => translate(makeLabel(key));
  const makeTextProps = () => ({
    label: withLabel ? label ?? t(type) : undefined,
    allowEmpty: true,
    showLink:
      withLink && entity ? buildProfileLink(type, id, parentType, parentId, partnerId) : undefined,
    children: renderer ? (
      renderer(entity)
    ) : (
      <div className={classes.ellipsis}>{getName?.[type]?.(entity ?? {}) ?? id}</div>
    ),
  });

  return (
    <TextField
      {...makeTextProps()}
      className={cx(classes.ellipsis, className)}
      icon={withDefaultIcon ? makeIcon[type]?.() || <></> : undefined}
    />
  );
};

const makeLabel = (key: string): string => `resources.events.show.${key}`;

export const makeIcon: Record<ObjectType, () => any> = {
  INDIVIDUAL: () => <IndividualIcon />,
  BUSINESS: () => <BusinessIcon />,
  EMPLOYEE: () => <EmployeeIcon />,
  PARTNER: () => <TrailSign />,
  TENANT: () => <PnLIcon />,
  SOLUTION: () => <StarIcon />,
};

const useStyles = makeStyles(() => ({
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default ReferenceField;
