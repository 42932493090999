import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import EmployeeForm from './EmployeeForm';
import { EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { Business, Employee, Individual } from '~/types';
import { FormDataConsumer } from 'react-admin';

import { label } from '../EmployeeList';
import { usePermissions } from 'react-admin';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

type FormData = {
  formData: Individual;
  [x: string]: any;
};

interface Props {
  employeeRecord: Employee;
  businessRecord: Business;
  closeParent?: () => void;
}

const EmployeeEdit = (props: Props) => {
  const { employeeRecord, businessRecord, closeParent } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeParent && closeParent();
  };

  const { permissions } = usePermissions();
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const classes = useStyles();
  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions?.includes('business.employee.update')}
      />
      <Drawer
        heading={`${translateLabel('editTeamMember')} "${employeeRecord.firstName || ''} ${
          employeeRecord.lastName || ''
        }"`}
        open={open}
        onClose={handleClose}
      >
        {employeeRecord?.id && (
          <div className={cx(classes.bottomSpacing, classes.uuid)}>{employeeRecord?.id}</div>
        )}
        <EditForm resource='employees' record={employeeRecord} closeParent={handleClose}>
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return <EmployeeForm businessRecord={businessRecord} scopedFormData={formData} />;
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  uuid: {
    color: theme.palette.grey[500],
  },
}));

export default EmployeeEdit;
