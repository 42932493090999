import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Drawer, H3 } from '~/components';
import { Button } from '~/components/ra';
import { ViewList } from '@material-ui/icons';
import { Reference } from '~/types';
import { archiveAffiliatedObject } from '~/api/accounts';
import { isEmpty } from 'lodash';
import ReferenceCard from './ReferenceCard';
import { useNotify } from '~/hooks';

interface Props {
  references?: Reference[];
  id: string;
  resource: 'accounts' | 'individuals' | 'businesses' | 'partners' | 'employees';
  refetch?: () => void;
  withArchive?: boolean;
}

const ViewAffiliatedObjects = (props: Props) => {
  const { references, id, refetch, withArchive, resource } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const translate = useTranslate();
  const t = (key: string) => translate(`components.AffiliatedObjects.${key}`);

  const notify = useNotify();
  const archive = async (affiliatedObjectId: string) => {
    await archiveAffiliatedObject(resource, id, affiliatedObjectId);
    handleClose();
    notify({
      message: translate(`utils.snackbarNotifications.dataUpdatedSuccess`),
      type: 'success',
    });
    refetch && refetch();
  };
  const active = references?.filter((reference) => !reference.deleted);
  const archived = references?.filter((reference) => reference.deleted);
  return (
    <>
      <Button label={t('viewAll')} onClick={handleOpen}>
        <ViewList />
      </Button>
      <Drawer heading={t('viewAllAffiliatedObjects')} open={open} onClose={handleClose}>
        <Grid container spacing={4}>
          {!isEmpty(active) && (
            <Grid item xs={12}>
              {active?.map((reference) => (
                <ReferenceCard
                  key={reference.id}
                  reference={reference}
                  archive={withArchive ? archive : undefined}
                  active
                  fullView
                />
              ))}
            </Grid>
          )}
          {!isEmpty(archived) && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <H3 topSpacing={0} bottomSpacing={2}>
                    {t('archivedAffiliatedObjects')}
                  </H3>
                </Grid>
                <Grid item xs={12}>
                  {archived?.map((reference) => (
                    <ReferenceCard key={reference.id} reference={reference} fullView />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
          {isEmpty(active) && isEmpty(archived) && (
            <Grid item xs={12}>
              {t('noAffiliatedObjects')}
            </Grid>
          )}
        </Grid>
      </Drawer>
    </>
  );
};

export default ViewAffiliatedObjects;
