import { downloadCSV } from 'react-admin';
import { time } from '~/utils';

import {
  createBackgroundTaskImport,
  getBackgroundTask,
  getBackgroundTaskResult,
} from './../api/backgroundTasks';
import { last } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { createBackgroundTask, CreateBackgroundTaskParams } from '~/api/backgroundTasks';
import { useReduxState } from '.';
import FileSaver from 'file-saver';
import { BackgroundTaskDialogType } from '~/components/BackgroundTask';

const useBackgroundTasksState = () => {
  return useReduxState<
    Record<CreateBackgroundTaskParams['type'], { id: string; isFinished: boolean }[]>
  >('backgroundTasks', {
    HEAD_TRANSACTION_EXPORT: [],
    DEAL_EXPORT: [],
    TENANT_INVOICE: [],
    INDIVIDUAL_EXPORT: [],
    BUSINESS_EXPORT: [],
    TEAM_MEMBER_EXPORT: [],
    EVENT_EXPORT: [],
    BALANCE_TRANSACTION_EXPORT: [],
    BANK_ACCOUNT_TRANSFER_EXPORT: [],
    INNBUCKS_INDIVIDUAL_IMPORT: [],
  });
};

export const useCreateBackgroundTaskImport = (options: any = {}) => {
  const [, setState] = useBackgroundTasksState();
  return useMutation(createBackgroundTaskImport, {
    onSuccess(res) {
      setState((prev) => ({
        ...prev,
        [res.taskType]: prev[res.taskType].concat([{ id: res.id, isFinished: false }]),
      }));
    },
    ...options,
  });
};

export const useCreateBackgroundTask = (options: any = {}) => {
  const [, setState] = useBackgroundTasksState();
  return useMutation(createBackgroundTask, {
    onSuccess(res) {
      setState((prev) => ({
        ...prev,
        [res.taskType]: prev[res.taskType].concat([{ id: res.id, isFinished: false }]),
      }));
    },
    ...options,
  });
};

export const useBackgroundTasksResult = ({
  type,
  queryArguments,
  dialogType,
}: UseBackgroundTasksProps) => {
  return useMutation(getBackgroundTaskResult, {
    onSuccess({ data, headers }) {
      if (dialogType === 'export') {
        let extension = headers['content-type']?.split('/')?.[1];
        switch (extension) {
          case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            extension = 'xlsx';
            break;
          default:
            break;
        }
        const fileName = getFileName({ type, queryArguments });
        if (extension === 'csv') {
          downloadCSV(data, fileName);
        } else {
          const blob = new Blob([data], { type: headers['content-type'] });
          FileSaver.saveAs(blob, `${getFileName({ type, queryArguments })}.${extension}`);
        }
      }
    },
  });
};

export const getFileName = (props: UseBackgroundTasksProps) => {
  const { type, queryArguments } = props;
  switch (type) {
    case 'TENANT_INVOICE':
      return `Invoice_${queryArguments.headTransactionId?.split('.')?.join(',') || ''}`;
    default:
      return `${type} ${time().format('LL LT')}`;
  }
};

interface UseBackgroundTasksProps {
  type: CreateBackgroundTaskParams['type'];
  queryArguments?: any;
  dialogType?: BackgroundTaskDialogType;
  isEnabled?: boolean;
}

export const useBackgroundTasks = ({
  type,
  queryArguments,
  dialogType,
  isEnabled,
}: UseBackgroundTasksProps) => {
  const [state, setState] = useBackgroundTasksState();
  const lastTask = last(state[type]);
  const taskId = lastTask?.id;
  const isFinished = lastTask?.isFinished;
  const setFinished = () => {
    setState((prev) => ({
      ...prev,
      [type]: prev[type].map((item) => {
        if (item.id === taskId) {
          return { ...item, isFinished: true };
        }
        return item;
      }),
    }));
  };

  const { mutate: getResult, data: backgroundTaskResultData } = useBackgroundTasksResult({
    type,
    queryArguments,
    dialogType,
  });
  const { data: currentBackgroundTasks, remove: currentBackgroundTaskRemove } = useQuery(
    ['backgroundTasks', taskId],
    () => getBackgroundTask(taskId!),
    {
      enabled: !!taskId && !isFinished && isEnabled,
      refetchInterval: 1000,
      onSuccess(res) {
        if (res.status === 'DONE' || res.status === 'FAILED') {
          setFinished();
        }
        if (res.status === 'DONE') {
          getResult({ id: res.id, resultType: res.resultType });
        }
      },
    }
  );
  return { currentBackgroundTasks, currentBackgroundTaskRemove, backgroundTaskResultData };
};
