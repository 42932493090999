export default {
  name: 'Welcome',
  individuals: 'Individuals',
  businesses: 'Businesses',
  configuration: 'Configuration',
  // Individuals
  individualGuide: 'Demo guide to the individual wallet',
  downloadApp: 'Download demo mobile app',
  singUpDemo: 'Sign up to the demo mobile app',
  singUpDemoText:
    'Please use your real phone number to sign up as we will send a one-time password to it. Feel free to fill other fields with dummy data.  This is demo environment, so kindly avoid to input or upload any sensitive data.',
  topupYourAccount: 'Top up your account from a bank account',
  topupYourAccountText:
    'Ok, this time not your real bank account:) Use this demo account AE123456789012345.',
  makeP2P: 'Make P2P payment by phone number',
  makeP2PText:
    'You may create a second profile and transfer to it or use our dummy account +971551234567.',
  findJustCreated: 'Find just created profile by phone number or name in the',
  individualsScreen: 'Individuals screeen',
  youWillSee:
    'You will see account instantly created for you, current balance and limits powered by Veengu financial service. Explore profile details, play with documents, contacts and memos. View all payments you’ve just made in the “Transactions” tab.',
  goToBusinesses: "Go to 'Businesses' tab and explore our business account facilities",
  // Businesses
  businessGuide: 'Demo guide to the solution for business customers',
  playWith: 'Play with business profiles in the',
  businessScreen: 'Businesses screen',
  youWillFind:
    'You will find account, limits, profile management similar to individual customers screen. But not only as businesses may have a complex organizational structure. Add a new store as a location. Register a new QR terminal. Manage team members.',
  createYourNewBusiness: 'Create your new business in the',
  newBusiness: 'New Business screen',
  issueNewCode: 'Issue a new static QR code',
  defineGood:
    'Define a good you’d like to sell, put a price. Download and open static QR as a PNG image',
  makePurchase: 'Make a purchase by scanning generated QR with your individual app',
  checkFees:
    'Check the fees and confirm the payment. Then view all transaction details in the Individual and Business screens.',
  goToConfiguration: "Go to the 'Configuration' tab and explore some of Veengu settings",
  // Configuration
  demoGuideSettings: 'Demo guide to the Veengu settings',
  open: 'Open the',
  solutionScreen: 'Solution screen',
  viewFeatures:
    'View the features included in demo set and some of the settings with expose in this environment.',
  viewPricing: 'View the pricing and limits setup',
  forEachTransaction:
    'For each transaction you can setup a fee, select appropriate fee algorithm and configure parameters. The same is applicable to the limits. On top you may request us to build a custom fee or limit if such algorithm is not included to Veengu library today.',
  anyQuestions:
    'Any questions? Send a request to sales@veengu.com or to a Veengu team member you are already in touch with.',
  // Title
  title: 'Veengu Demo Guide',
  subtitle:
    'Please use this tutorial to perform basic wallet operations or feel free to explore our Dashboard on your own',
  Aside: {
    welcome: 'welcome to Veengu demo!',
    primaryEmail: 'Primary email',
  },
  DemoAlert: {
    title: 'Alert',
    text: 'You are working in a public DEMO zone. Do not provide any sensitive and personal data!',
    ok: 'Ok, I get it',
  },
};
