import React, { FC } from 'react';
import {
  useTranslate,
  SingleFieldList,
  Datagrid,
  ArrayField,
  ListController,
  ListContextProvider,
  Filter,
  TextInput,
  BooleanInput,
  FunctionField,
} from 'react-admin';
import { TextField as TextFieldComponent } from '~/components';
import {
  ShowController,
  NoPermissions,
  ChipField,
  TextField as RaTextField,
  StatusField,
} from '~/components/ra';
import { Employee } from '~/types';
import { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';
import { ListView } from '~/layout';
import { usePermissions } from 'react-admin';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { Longdash, hasTranslation, time } from '~/utils';
import ReferenceField from '~/components/ReferenceField';
import EmployeeRoleFilter from './components/EmployeeRoleFilter';
import { useListContext } from 'react-admin';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import { omit } from 'lodash';

const listBodyStyles = (theme: any) => {
  return createStyles({
    content: {
      fontSize: 14,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      paddingTop: 16,
      paddingBottom: 16,
    },
    error: {
      color: theme.palette.error.main,
    },
  });
};

export const label = (key: string) => `resources.employees.fields.${key}`;

export const resource = 'employees';

const ListBody = withStyles(listBodyStyles)(() => {
  const translate = useTranslate();
  const t = (key: any) => translate(label(key));

  const translateField = (path: string, key: string) => {
    const fullPath = `${path}${key}`;
    return hasTranslation(fullPath) ? translate(fullPath) : key;
  };

  const { data: tEmployees } = useEmployeeRolesTranslations();
  return (
    <Datagrid rowClick='show' size='small' selectedIds={null}>
      <FunctionField
        label={t('name')}
        sortable={false}
        render={(record: Employee) => {
          return (
            <TextFieldComponent ellipsis style={{ fontWeight: 400 }}>
              {record?.firstName + ' ' + record?.lastName}
            </TextFieldComponent>
          );
        }}
      />
      <FunctionField
        label={t('businessEntity')}
        sortable={false}
        render={(record: Employee) => {
          return (
            <ReferenceField
              withLabel={false}
              withLink
              record={{
                id: record.businessId,
                type: 'BUSINESS',
              }}
            />
          );
        }}
      />
      <RaTextField
        label={t('phoneNumber')}
        addLabel={false}
        fn={(record: Employee) => record?.phoneNumber}
        sortable={false}
        style={{ fontWeight: 400 }}
      />
      <FunctionField
        label={t('email')}
        sortable={false}
        render={(record: Employee) => {
          return (
            <TextFieldComponent ellipsis style={{ fontWeight: 400 }}>
              {record?.email}
            </TextFieldComponent>
          );
        }}
      />
      <FunctionField
        label={t('document._')}
        render={(record: Employee) => {
          const documentType = record?.document?.type || Longdash;
          const documentNumber = record?.document?.number || Longdash;
          const path = 'components.ra.fields.DocumentCard.documentType.';
          return (
            <TextFieldComponent compact label={translateField(path, documentType)}>
              {documentNumber}
            </TextFieldComponent>
          );
        }}
        sortable={false}
      />
      <ArrayField source='roles' label={t('roles')} sortable={false}>
        <SingleFieldList linkType={false}>
          <ShowController>
            {({ showRecord, ...rest }) => {
              if (!showRecord || !tEmployees) return '';
              return (
                <ChipField record={{ name: tEmployees[showRecord] }} source='name' {...rest} />
              );
            }}
          </ShowController>
        </SingleFieldList>
      </ArrayField>
      <FunctionField
        label={t('createdAt')}
        render={(record: Employee) => {
          const createdAt = time(record?.createdAt);
          return (
            <TextFieldComponent compact label={createdAt.format('LL')}>
              {createdAt.format('LT')}
            </TextFieldComponent>
          );
        }}
        sortable={false}
      />
      <StatusField label={t('status')} sortable={false} defaultValue='ACTIVE' />
    </Datagrid>
  );
});

const handleFilters = (filters: any) => {
  if (!filters) return {};
  return filters;
};

const ExportAction = () => {
  const { filterValues } = useListContext();
  // For backend compatibility we need to change the key from roles to role.
  const restFilterValues = omit(filterValues, ['roles']);
  const newFilterValues = { ...restFilterValues, role: filterValues.roles || [] };
  return (
    <BackgroundTaskExport type='TEAM_MEMBER_EXPORT' queryArguments={handleFilters(newFilterValues)} />
  );
};

const EmployeeList: FC<any> = (props) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions.includes('business.employee.list')) return <NoPermissions />;
  return (
    <>
      <ListController {...props}>
        {(controllerProps: any) => {
          return (
            <ListContextProvider value={controllerProps}>
              <ListView
                filters={<Filters />}
                {...controllerProps}
                bulkActionButtons={false}
                actions={
                  <div style={{ minWidth: 240, marginTop: 'auto', textAlign: 'right' }}>
                    <ExportAction {...props} />
                  </div>
                }
              >
                <ListBody />
              </ListView>
            </ListContextProvider>
          );
        }}
      </ListController>
    </>
  );
};

const Filters = (props: any) => {
  const translate = useTranslate();
  const t = (key: any) => translate(label(key));
  const classes = useStyles();
  return (
    <Filter {...props} className={classes.form} defaultValues={{ roles: [] }}>
      <TextInput
        name='name'
        source='name'
        label={t('name')}
        alwaysOn
        className={classes.formControl}
      />
      <EmployeeRoleFilter
        name='roles'
        source='roles'
        label={t('roles')}
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        name='businessId'
        source='businessId'
        label={t('businessId')}
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        name='email'
        source='email'
        label={t('email')}
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        name='phoneNumber'
        source='phoneNumber'
        label={t('phoneNumber')}
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        name='terminalId'
        source='terminalId'
        label={t('terminalId')}
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        name='workLocationId'
        source='workLocationId'
        label={t('workLocationId')}
        alwaysOn
        className={classes.formControl}
      />
      <BooleanInput label={label('withDeleted')} source='withDeleted' alwaysOn />
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  filters: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  form: {
    '& > div': {
      '& > div:last-child': {
        width: theme.spacing(1),
      },
    },
  },
  formControl: {
    width: 200,
  },
}));

export default EmployeeList;
