import React, { Fragment, FC } from 'react';
import { useTranslate, Button } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Employee } from '~/types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { label } from '../EmployeeList';

const useStyles = makeStyles((theme) => ({
  color: {
    color: theme.palette.secondary.main,
  },
}));

interface ViewDetailsButtonProps {
  employeeRecord: Employee;
}

const ViewDetailsButton: FC<ViewDetailsButtonProps> = (props) => {
  const { employeeRecord } = props;
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));
  const classes = useStyles();
  const handleOpenInNewTab = () => {
    window.open(`${window.location.origin}/employees/${employeeRecord?.id}/show`, '_blank');
  };
  return (
    <Fragment>
      <Button
        label={translateLabel('viewDetails')}
        onClick={handleOpenInNewTab}
        className={classes.color}
      >
        <SvgIcon>
          <OpenInNewIcon />
        </SvgIcon>
      </Button>
    </Fragment>
  );
};

export default ViewDetailsButton;
