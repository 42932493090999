import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import { CreateForm } from '~/components/ra';
import { CreateButton, Drawer } from '~/components';
import EmployeeForm from './EmployeeForm';
import { Business, Individual } from '~/types';
import { FormDataConsumer } from 'react-admin';

import { label } from '../EmployeeList';

interface Props {
  businessRecord: Business;
  permissions: string[];
}

type FormData = {
  formData: Individual;
  [x: string]: any;
};

const EmployeeCreate = (props: Props) => {
  const { businessRecord, permissions } = props;
  const { id, solutionId } = businessRecord;
  const createRecord = {
    businessId: id,
    solutionId,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  return (
    <Fragment>
      <CreateButton
        variant='add'
        onClick={handleOpen}
        disabled={!permissions.includes('business.employee.create')}
      />
      <Drawer heading={translateLabel('addTeamMember')} open={open} onClose={handleClose}>
        <CreateForm
          resource='employees'
          target='businesses'
          id={id}
          record={createRecord}
          closeParent={handleClose}
        >
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return <EmployeeForm businessRecord={businessRecord} scopedFormData={formData} />;
            }}
          </FormDataConsumer>
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default EmployeeCreate;
