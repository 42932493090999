import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { SelectInput, TextInput, useTranslate, required } from 'react-admin';
import { CreateButton, Drawer } from '~/components';
import { CreateForm } from '~/components/ra';
import ReferenceField from '~/components/ReferenceField';
import useEventEntity from '~/hooks/eventEntities';
import { isEmpty } from 'lodash';
import { ReferenceType } from '~/types/Reference';
import { ObjectType } from '~/types';

type FormDataMappingType = {
  [resource in string]: {
    [affiliationType in ReferenceType]: {
      [entities in ObjectType]: ObjectType[];
    };
  };
};

const formDataMapping: Readonly<FormDataMappingType> = {
  accounts: {
    SHARED_WITH: {
      BUSINESS: [],
      INDIVIDUAL: [],
    },
  },
  employees: {
    COMMISSION_PAY_TO: {
      BUSINESS: [],
      INDIVIDUAL: [],
      PARTNER: [],
    },
  },
};

interface Props {
  id: string;
  refetch?: () => void;
  resource: 'accounts' | 'individuals' | 'businesses' | 'partners' | 'employees';
}

const AddAffiliatedObject = (props: Props) => {
  const { id, refetch, resource } = props;
  const [typeValue, setTypeValue] = useState('');
  const [objectTypeValue, setObjectTypeValue] = useState('');
  const [objectUUID, setObjectUUID] = useState('');
  const [subObjectTypeValue, setSubObjectTypeValue] = useState('');
  const [subObjectUUID, setSubObjectUUID] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setTypeValue('');
    setObjectTypeValue('');
    setObjectUUID('');
    setSubObjectTypeValue('');
    setSubObjectUUID('');
    setOpen(false);
    refetch && refetch();
  };
  const handleTypeValueChange = (e: any) => {
    setTypeValue(e.target.value);
  };
  const handleObjectTypeValueChange = (e: any) => {
    setObjectTypeValue(e.target.value);
  };
  const handleObjectUUIDChange = (e: any) => {
    setObjectUUID(e.target.value);
  };
  const handleSubObjectTypeValueChange = (e: any) => {
    setSubObjectTypeValue(e.target.value);
  };
  const handleSubObjectUUIDChange = (e: any) => {
    setSubObjectUUID(e.target.value);
  };

  const translate = useTranslate();
  const t = (key: string) => translate(`components.AffiliatedObjects.${key}`);

  const { data: entity, isLoading: isLoadingEntity } = useEventEntity(
    { id: objectUUID, type: objectTypeValue },
    !!objectTypeValue && !!objectUUID
  );
  const { data: subEntity, isLoading: isLoadingSubEntity } = useEventEntity(
    { id: subObjectUUID, type: subObjectTypeValue },
    !!subObjectTypeValue && !!subObjectUUID
  );

  const createChoices = (data: any) => {
    return data.map((object: string) => ({
      id: object,
      name: t(object),
    }));
  };

  const formData = formDataMapping[resource];
  const affiliatedObjectKeys = Object.keys(formData);
  const affiliatedObjectChoices = createChoices(affiliatedObjectKeys);
  const objectTypeKeys = typeValue ? Object.keys(formData[typeValue]) : [];
  const objectTypeChoices = createChoices(objectTypeKeys);
  const subObjectChoices =
    typeValue && objectTypeValue ? createChoices(formData[typeValue][objectTypeValue]) : [];
  return (
    <>
      <CreateButton variant='add' onClick={handleOpen} />
      <Drawer heading={t('addAffiliatedObject')} open={open} onClose={handleClose}>
        <CreateForm
          disabled={!entity || (!isEmpty(subObjectChoices) && !subEntity)}
          resource={resource}
          id={id}
          subresource='references'
          closeParent={handleClose}
        >
          <>
            <SelectInput
              source='referenceType'
              onChange={handleTypeValueChange}
              label={t('affiliationType')}
              choices={affiliatedObjectChoices}
              validate={required()}
            />
            <SelectInput
              source={'objectType'}
              value={objectTypeValue}
              onChange={handleObjectTypeValueChange}
              label={t('objectType')}
              choices={objectTypeChoices}
              disabled={!typeValue}
              validate={required()}
            />
            <TextInput
              source={'objectId'}
              value={objectUUID}
              onBlur={handleObjectUUIDChange}
              label={t('objectUUID')}
              disabled={!objectTypeValue}
              validate={required()}
            />
            {objectUUID &&
              (entity || isLoadingEntity ? (
                <ReferenceField
                  withDefaultIcon
                  record={{ id: objectUUID, type: objectTypeValue }}
                />
              ) : (
                <Alert severity='warning'>{t('warning')}</Alert>
              ))}
            {objectUUID && !isEmpty(subObjectChoices) && <br />}
            {objectTypeValue && !isEmpty(subObjectChoices) && (
              <>
                <SelectInput
                  source={'subObjectType'}
                  value={subObjectTypeValue}
                  onChange={handleSubObjectTypeValueChange}
                  label={t('subObjectType')}
                  choices={subObjectChoices}
                  disabled={!objectTypeValue}
                  validate={required()}
                  hidden={!objectTypeValue}
                />
                <TextInput
                  source={'subObjectId'}
                  value={subObjectUUID}
                  onBlur={handleSubObjectUUIDChange}
                  label={t('subObjectUUID')}
                  disabled={!objectTypeValue}
                  validate={required()}
                  hidden={!objectTypeValue}
                />
              </>
            )}
            {subObjectUUID &&
              !isEmpty(subObjectChoices) &&
              (subEntity || isLoadingSubEntity ? (
                <ReferenceField
                  withDefaultIcon
                  record={{ id: subObjectUUID, type: subObjectTypeValue }}
                />
              ) : (
                <Alert severity='warning'>{t('warning')}</Alert>
              ))}
          </>
        </CreateForm>
      </Drawer>
    </>
  );
};

export default AddAffiliatedObject;
