import React, { CSSProperties } from 'react';
import { time } from '~/utils';

import { Column } from '~/components/VirtualizedList';
import { AmountField, StatusField, TextField } from '~/components';
import { EventType } from '~/types/Event';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReferenceField from '~/components/ReferenceField';
import theme from '~/theme';
import { Batch } from '~/types/Batch';

interface Props {
  children: any;
  rowData: Batch;
  column: Column<EventType>;
  style?: CSSProperties;
}

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;
  const classes = useStyles();

  switch (column.id) {
    case 'created': {
      const createdAt = time(rowData.created);
      return (
        <TextField compact label={createdAt.format('LL')}>
          {createdAt.format('LTS')}
        </TextField>
      );
    }
    case 'description': {
      return (
        <TextField compact label={rowData.type.toLowerCase()} className={classes.ellipsis}>
          {rowData.data?.description}
        </TextField>
      );
    }
    case 'object':
      return <ReferenceField record={{ type: rowData.objectType, id: rowData.objectId }} />;
    case 'actor': {
      return <ReferenceField record={{ type: rowData.actorType, id: rowData.actorId }} />;
    }
    case 'numberOfRecords':
      return rowData.recordsTotal;
    case 'instructedAmount':
      return <AmountField amount={children} style={{ fontWeight: 400 }} />;
    case 'status': {
      return <StatusField status={props.children} />;
    }
    default: {
      return null;
    }
  }
};

const useStyles = makeStyles(() => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'relative',
  },
  chip: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
  },
  number: {
    padding: 4,
    paddingBottom: 0,
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(1),
  },
}));

export default CustomCell;
