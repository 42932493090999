import React, { useState } from 'react';
import { JSONSchemaType } from 'ajv';
import { Tab, useTranslate, useShowController, usePermissions } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';
import ajv from '~/utils/ajv';
import { Show, PaperTabbedShowLayout } from '~/layout';
import { buildName, Longdash } from '~/utils';
import {
  Accounts,
  MerchantCategoryCodeField,
  TextField,
  ShowController,
  NoPermissions,
} from '~/components/ra';
import {
  TransactionsHistory,
  TextField as IconTextFieldComponent,
  DocumentCard,
  JsonSchemaView,
  H2,
  PhoneField,
  Notes,
  AuditLogWidget,
  UpdateContext,
  AddressTable,
  RecordTitle,
  ProfileTags,
} from '~/components';
import {
  Aside,
  IndustryField,
  EditAccountInfo,
  AddOrEditDocument,
  EditAdditionalProperties,
} from './components';
import LocationsList from '../locations/LocationsList';
import TerminalsList from '../terminals/TerminalsList';
import EmployeesDatagrid from '../employees/components/EmployeesDatagrid';
import PaymentDetailsList from '../paymentDetails/PaymentDetailsList';
import { Business } from '~/types/businesses';
import BeneficiariesList from '../beneficiaries/BeneficiariesList';
import { useConfiguration, useDeepEffect, useSolution } from '~/hooks';
import { useGetChangelogData, useGetChangelogsData } from '~/hooks/useGetChangelogsData';
import { Alert, Skeleton } from '@material-ui/lab';
import ProfileLimits from '~/components/ProfileLimits';
import { useRightContentSpacing } from '~/hooks/styles';
import FeedbackStatistic from '~/components/FeedbackStatistic';
import ExternalProfileLinks from '~/components/ExternalProfileLinks';
import EventLogWidget from '~/components/Events/EventLogWidget';
import EventLogKYC from '~/components/Events/EventLogKYC';
import ProfileHistoryDrawer from '~/components/ProfileHistoryDrawer';
import { EditAddress } from '~/components';
import { EditContacts } from '~/components';
import AffiliatedObjects from '~/components/AffiliatedObjects';

const label = (key: string): string => `resources.businesses.fields.${key}`;

const LayoutWithContext = (props: any) => {
  const { history, resource, record, changelogMode, selectedChangelogTime } = props;
  const currentPathname = history?.location.pathname;
  const systemLogPathname = `/${resource}/${record?.id || ''}/show/systemLog`;
  const eventLogPathname = `/${resource}/${record?.id || ''}/show/eventLog`;
  const { permissions } = usePermissions();

  return (
    <PaperTabbedShowLayout
      {...props}
      changelogMode={changelogMode}
      bottomContent={
        currentPathname === eventLogPathname && permissions?.includes?.('business.event.list') ? (
          <EventLogWidget
            objectId={record.id}
            objectType='businesses'
            history={history}
            changelogMode={changelogMode}
            selectedChangelogTime={selectedChangelogTime}
          />
        ) : currentPathname === systemLogPathname ? (
          <AuditLogWidget profileId={record.id} history={history} />
        ) : null
      }
    />
  );
};

interface ProfileSchema {
  schema?: JSONSchemaType<Record<string, any>>;
  error?: boolean;
}

const BusinessShow = (props: any) => {
  const { history, resource } = props;
  // States & handlers for ChangeLog.
  const [open, setOpen] = useState(false);
  const [changelogMode, setChangelogMode] = useState(false);
  const [selectedChangelog, setSelectedChangelog] = useState<number | undefined>(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTurnOnChangelogMode = (id: number) => {
    setChangelogMode(true);
    setSelectedChangelog(id);
    handleClose();
  };
  const handleTurnOffChangelogMode = () => {
    setChangelogMode(false);
    setSelectedChangelog(undefined);
  };

  const { permissions } = usePermissions();
  const hasChangelogPermissionList = permissions?.includes('business.changelog.list');
  const hasChangelogPermissionView = permissions?.includes('business.changelog.view');

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const showController = useShowController(props);

  const { data: selectedChangelogData } = useGetChangelogData(
    resource,
    showController?.record?.id!,
    !!hasChangelogPermissionList && !!selectedChangelog,
    selectedChangelog
  );

  // Timestamp for event logs.
  const selectedChangelogTime = changelogMode ? selectedChangelogData?.timestamp : undefined;

  // Record data for business.
  const showRecord: Business | undefined =
    changelogMode && selectedChangelogData ? selectedChangelogData?.data : showController.record;

  const currentPathname = history?.location.pathname;
  const accountsPathname = `/${resource}/${showRecord?.id || ''}/show`;
  const profilePathname = `/${resource}/${showRecord?.id || ''}/show/profile`;

  // Fetch the changelogs data if user has permissions.

  const { isLoading: changelogsLoading, data: changelogsData, refetch } = useGetChangelogsData(
    resource,
    showRecord?.id!,
    !!hasChangelogPermissionList && currentPathname === profilePathname
  );

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  const { data: solution } = useSolution(resource.solutionId);

  const [profileSchema, setProfileSchema] = useState<ProfileSchema>();
  const { data: configuration } = useConfiguration(showRecord?.solutionId);
  useDeepEffect(() => {
    if (configuration) {
      const schemaRaw: string | undefined = configuration?.veengu_business_profile?.profileSchema;
      if (schemaRaw) {
        try {
          const anySchema = JSON.parse(schemaRaw) as any;
          if (anySchema.type !== 'object') {
            throw new Error('Json schema type !== object');
          }
          const schema = anySchema as JSONSchemaType<Record<string, any>>;
          ajv.compile(schema);
          console.log(`using business profile schema: `, schema);
          setProfileSchema({ schema });
        } catch (e) {
          console.error(`failed to parse business profile schema`, e);
          setProfileSchema({ error: true });
        }
      } else {
        console.error('no business profile schema provided');
        setProfileSchema({ error: true });
      }
    }
  }, [configuration]);

  // Prevent undefined check in child component.
  if (!showRecord) return null;
  if (!permissions) return null;
  if (!permissions.includes('business.view')) return <NoPermissions />;

  const hasPaymentDetailsSupport = solution?.features?.includes(
    'veengu_business_purchase_byStaticQr'
  );
  const hasBeneficiarySupport =
    solution?.features?.includes('veengu_business_beneficiary_management') &&
    permissions?.includes('beneficiary.list');

  const additionalProperties: any[] = [];
  Object.keys(showRecord?.additionalProperties || {}).forEach((propertyKey) => {
    additionalProperties.push({
      key: propertyKey,
      value: showRecord?.additionalProperties![propertyKey],
    });
  });

  const hasExternalProfileLinksPermission = permissions?.includes('externalProfileLink.list');

  const hasTagsViewPermission = permissions?.includes('business.tags.view');
  return (
    <Show
      {...props}
      title={
        <RecordTitle
          headline={(record: Business, resource: 'businesses') =>
            buildName(resource, record) || '???'
          }
          record={showRecord}
          avatarSource='branding.icon'
          resource='businesses'
          openDrawer={handleOpen}
          hasChangelogPermissionList={hasChangelogPermissionList}
          hasChangelogPermissionView={hasChangelogPermissionView}
          hasPathnameProfile={currentPathname === profilePathname}
          changelogMode={changelogMode}
          handleTurnOffChangelogMode={handleTurnOffChangelogMode}
          changelogsData={changelogsData || []}
          setSelectedChangelog={setSelectedChangelog}
          selectedChangelog={selectedChangelog}
          refetch={refetch}
        />
      }
    >
      {showRecord && <UpdateContext data={{ solutionId: showRecord.solutionId }} />}
      <LayoutWithContext
        history={props.history}
        changelogMode={changelogMode}
        selectedChangelogTime={selectedChangelogTime}
      >
        <Tab
          className={classes.tab}
          label='resources.businesses.tabs.accounts'
          disabled={!permissions?.includes('business.account.view') || changelogMode}
        >
          <Accounts
            permissions={permissions}
            resource='businesses'
            profileId={showRecord?.id as string}
            basePath={props.basePath}
            solutionId={showRecord?.solutionId}
          />
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.businesses.tabs.profile'
          path='profile'
          exact
          disabled={!permissions?.includes('business.view')}
        >
          {hasChangelogPermissionList && (
            <ProfileHistoryDrawer
              open={open}
              handleClose={handleClose}
              translate={translateLabel}
              handleTurnOnChangelogMode={handleTurnOnChangelogMode}
              changelogsData={changelogsData}
              changelogsLoading={changelogsLoading}
              hasChangelogPermissionView={hasChangelogPermissionView}
              selectedChangelog={selectedChangelog}
            />
          )}
          <div className={classes.section}>
            <header className={classes.header}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {translateLabel('profileInfo')}
              </H2>
              {!changelogMode && (
                <EditAccountInfo
                  permissions={permissions}
                  actionsId='section-actions'
                  record={showRecord}
                  {...props}
                />
              )}
            </header>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid xs spacing={2} item container direction='column'>
                  <Grid item>
                    <TextField
                      source='legalName'
                      label={translateLabel('legalName')}
                      record={showRecord}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      source='tradeName'
                      label={translateLabel('tradeName')}
                      record={showRecord}
                    />
                  </Grid>
                  <Grid item>
                    <IndustryField
                      source='industry'
                      label={translateLabel('industry')}
                      record={showRecord}
                    />
                  </Grid>
                </Grid>
                <Grid xs spacing={2} item container direction='column'>
                  <Grid item>
                    <TextField
                      source='legalType'
                      label={translateLabel('legalType')}
                      record={showRecord}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      source='tradeNameLocalized'
                      label={translateLabel('tradeNameLocalized')}
                      record={showRecord}
                    />
                  </Grid>
                  <Grid item>
                    <TextField label={translateLabel('merchantCategoryCode')} record={showRecord}>
                      <MerchantCategoryCodeField />
                    </TextField>
                  </Grid>
                </Grid>
                <Grid xs spacing={2} item container direction='column'>
                  <Grid item>
                    <IconTextFieldComponent label={translateLabel('primaryDocument._')}>
                      {showRecord?.primaryDocument && (
                        <div>{`${showRecord?.primaryDocument?.type} #${
                          showRecord?.primaryDocument?.number || Longdash
                        }`}</div>
                      )}
                    </IconTextFieldComponent>
                  </Grid>
                  <Grid item>
                    <TextField
                      source='description'
                      label={translateLabel('description')}
                      record={showRecord}
                      multiline={(showRecord?.description || '').length > 160}
                      textWrap
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={cx(classes.section, classes.topSpacing3)}>
            <header className={classes.header}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {translateLabel('contacts._')}
              </H2>
              {!changelogMode && (
                <EditContacts permissions={permissions} resource='businesses' record={showRecord} />
              )}
            </header>
            <div className={classes.container}>
              {showRecord?.contacts && showRecord?.contacts.length > 0 ? (
                <Grid container spacing={2}>
                  {showRecord?.contacts?.map((item, index) => {
                    const { id, channel } = item;
                    if (channel === 'PHONE') {
                      return (
                        <Grid xs={4} item key={index}>
                          <IconTextFieldComponent
                            label={translate(`components.ra.inputs.ChannelSelect.${channel}`)}
                          >
                            <PhoneField number={id} />
                          </IconTextFieldComponent>
                        </Grid>
                      );
                    }
                    return (
                      <Grid xs={4} item key={index}>
                        <IconTextFieldComponent
                          label={translate(`components.ra.inputs.ChannelSelect.${channel}`)}
                        >
                          {id}
                        </IconTextFieldComponent>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                translateLabel('noContacts')
              )}
            </div>
          </div>
          <div className={cx(classes.section, classes.topSpacing3)}>
            <header className={classes.header}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {translateLabel('address._')}
              </H2>
              {!changelogMode && (
                <EditAddress permissions={permissions} resource='businesses' record={showRecord} />
              )}
            </header>
            <div className={classes.container}>
              {showRecord?.address ? (
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <AddressTable address={showRecord?.address} changelogMode={changelogMode} />
                  </Grid>
                </Grid>
              ) : (
                translateLabel('noAddress')
              )}
            </div>
          </div>
          <div className={cx(classes.section, classes.topSpacing3)}>
            <header className={classes.header}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {translateLabel('documents._')}
              </H2>
              {!changelogMode && (
                <AddOrEditDocument permissions={permissions} record={showRecord} />
              )}
            </header>
            <div className={classes.container}>
              <Grid className={classes.noneMaxWidth} container spacing={2}>
                <Grid item xs={6} xl={5}>
                  <DocumentCard
                    record={showRecord as Business}
                    resource='businesses'
                    solutionId={showRecord?.solutionId}
                    country={showRecord?.country}
                    legalType={showRecord?.legalType}
                    documentFamily='BUSINESS'
                    document={showRecord?.primaryDocument}
                    primary
                    fullWidth
                    permissions={permissions}
                    editDocument={(index, primary, handleClose) => (
                      <AddOrEditDocument
                        record={showRecord}
                        edit
                        primary={primary}
                        index={index}
                        closeParentDrawer={handleClose}
                        permissions={permissions}
                      />
                    )}
                    changelogMode={changelogMode}
                  />
                </Grid>
                {showRecord?.documents?.map((document, index) => {
                  return (
                    <Grid item xs={6} xl={5} key={index}>
                      <DocumentCard
                        resource='businesses'
                        record={showRecord}
                        solutionId={showRecord?.solutionId}
                        country={showRecord?.country}
                        legalType={showRecord?.legalType}
                        documentFamily='BUSINESS'
                        index={index}
                        document={document}
                        fullWidth
                        permissions={permissions}
                        editDocument={(index, primary, handleClose) => (
                          <AddOrEditDocument
                            record={showRecord}
                            edit
                            primary={primary}
                            index={index}
                            closeParentDrawer={handleClose}
                            permissions={permissions}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
          <div className={cx(classes.section, classes.topSpacing3)}>
            <header className={classes.header}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {translateLabel('additionalProperties._')}
              </H2>
              {profileSchema?.schema &&
                !changelogMode &&
                Object.keys(profileSchema.schema.properties || {}).length > 0 && (
                  <EditAdditionalProperties
                    permissions={permissions}
                    record={showRecord!}
                    schema={profileSchema.schema}
                  />
                )}
            </header>
            <div className={classes.container}>
              {profileSchema?.schema ? (
                Object.keys(profileSchema.schema.properties || {}).length > 0 ? (
                  <JsonSchemaView
                    data={showRecord}
                    schema={profileSchema?.schema}
                    displayMissingProperties
                    className={classes.noneMaxWidth}
                  />
                ) : (
                  <>
                    {translateLabel('additionalProperties.empty')}
                    <br />
                    <br />
                  </>
                )
              ) : profileSchema?.error ? (
                <Alert severity='warning' style={{ marginBottom: '1em' }}>
                  {translateLabel('additionalProperties.badSchema')}
                </Alert>
              ) : (
                <Skeleton variant='text' width={200} height={24} />
              )}
            </div>
          </div>
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.individuals.tabs.transactionHistory'
          path='transactions'
          disabled={!permissions.includes('business.transaction.list') || changelogMode}
        >
          <TransactionsHistory permissions={permissions} target='businesses' />
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.businesses.tabs.locations'
          path='locations'
          disabled={!permissions.includes('business.location.list') || changelogMode}
        >
          <LocationsList permissions={permissions} />
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.businesses.tabs.team'
          path='team'
          disabled={!permissions.includes('business.employee.list') || changelogMode}
        >
          <EmployeesDatagrid permissions={permissions} />
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.businesses.tabs.terminals'
          path='terminals'
          disabled={!permissions.includes('business.terminal.list') || changelogMode}
        >
          <TerminalsList permissions={permissions} />
        </Tab>
        {hasPaymentDetailsSupport && (
          <Tab
            className={classes.tab}
            label='resources.businesses.tabs.paymentDetails'
            path='paymentDetails'
            disabled={changelogMode}
          >
            <PaymentDetailsList permissions={permissions} />
          </Tab>
        )}
        {hasBeneficiarySupport && (
          <Tab
            className={classes.tab}
            label='resources.businesses.tabs.beneficiaries'
            path='beneficiaries'
            disabled={changelogMode}
          >
            <BeneficiariesList permissions={permissions} />
          </Tab>
        )}
        {permissions?.includes('business.view') && permissions?.includes('business.event.list') && (
          <Tab
            className={classes.tab}
            label='resources.businesses.tabs.eventLog'
            path='eventLog'
            disabled={changelogMode}
          />
        )}
        {permissions?.includes('feedback.list') && (
          <Tab
            className={classes.tab}
            label='resources.businesses.tabs.feedback'
            path='feedback'
            disabled={changelogMode}
          >
            <FeedbackStatistic groupId={showRecord?.id} groupType='BUSINESS' />
          </Tab>
        )}
        {permissions?.includes('auditLog.list') && (
          <Tab
            className={classes.tab}
            label='resources.businesses.tabs.systemLog'
            path='systemLog'
            disabled={changelogMode}
          />
        )}
        {permissions?.includes('business.view') && permissions?.includes('business.event.list') && (
          <Tab
            className={classes.tab}
            label='resources.businesses.tabs.checks'
            path='checks'
            disabled={changelogMode}
          >
            <EventLogKYC objectType='business' object={showRecord} />
          </Tab>
        )}
      </LayoutWithContext>
      <ShowController>
        {({ showRecord, ...rest }: any) => {
          const record =
            changelogMode && selectedChangelogData ? selectedChangelogData?.data : showRecord;
          return (
            <Grid container item xs direction='column' spacing={spacing}>
              <Grid item className={classes.rightContent}>
                <Aside
                  record={
                    changelogMode && selectedChangelogData
                      ? selectedChangelogData?.data
                      : showRecord
                  }
                  {...rest}
                  changelogMode={changelogMode}
                />
              </Grid>
              {(currentPathname === accountsPathname || currentPathname === profilePathname) &&
                hasTagsViewPermission && (
                  <Grid item className={classes.rightContent}>
                    <ProfileTags
                      profileId={showRecord.id}
                      resource='businesses'
                      permissions={permissions}
                      record={
                        changelogMode && selectedChangelogData
                          ? selectedChangelogData?.data
                          : showRecord
                      }
                      changelogMode={changelogMode}
                    />
                  </Grid>
                )}
              {currentPathname === accountsPathname && (
                <Grid item className={classes.rightContent}>
                  <ProfileLimits
                    profileId={showRecord.id}
                    resource='businesses'
                    solutionId={showRecord?.solutionId}
                    permissions={permissions}
                  />
                </Grid>
              )}
              {currentPathname === accountsPathname && hasExternalProfileLinksPermission && (
                <Grid item className={classes.rightContent}>
                  <ExternalProfileLinks
                    profileId={showRecord.id}
                    profileType='BUSINESS'
                    permissions={permissions}
                  />
                </Grid>
              )}
              {currentPathname === profilePathname && showRecord.references && (
                <AffiliatedObjects
                  id={showRecord.id}
                  references={record?.references}
                  resource='businesses'
                  changelogMode={changelogMode}
                />
              )}
              {currentPathname === profilePathname && !changelogMode && (
                <Grid item className={cx(classes.rightContent, classes.fullWidthGrow)}>
                  <Notes referenceId={showRecord.id} profileType='BUSINESS' />
                </Grid>
              )}
            </Grid>
          );
        }}
      </ShowController>
    </Show>
  );
};

type StylesPrpos = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesPrpos>((theme) => ({
  tab: {
    minWidth: 80,
    flexGrow: 1,
    maxWidth: 200,
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
  header: {
    marginBottom: ({ spacing }) => theme.spacing(spacing),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noneMaxWidth: {
    maxWidth: 'none',
  },
  fullWidthGrow: {
    flexGrow: 1,
  },
  topSpacing3: {
    marginTop: ({ spacing }) => theme.spacing(spacing),
  },
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
}));

export default BusinessShow;
