import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import BarChartIcon from '@material-ui/icons/BarChart';
import { H3 } from '~/components';
import { useApi } from '~/hooks';
import { getEmployeeMetrics } from '~/api/employees';
import Metric from '~/types/metrics';
import { MetricField } from '~/components/MetricField';
import { label } from '../EmployeeList';

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  topSpacing: {
    marginTop: theme.spacing(1),
  },
  metrics: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
}));

interface MetricsProps {
  employeeId: string;
  emptyMessage?: boolean;
}

const Metrics = ({ employeeId, emptyMessage }: MetricsProps) => {
  const [{ data: metrics }] = useApi<Metric[]>(() => getEmployeeMetrics(employeeId), [employeeId]);

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const classes = useStyles();

  return metrics && metrics.length > 0 ? (
    <>
      <H3 icon={<BarChartIcon />} className={classes.topSpacing}>
        {t('metrics._')}
      </H3>
      <Grid
        container
        direction='column'
        spacing={2}
        className={cx(classes.metrics, classes.bottomSpacing)}
      >
        {metrics.map((metric) => (
          <Grid item key={metric.key}>
            <MetricField name={metric.name} value={metric.value} target={metric.target} />
          </Grid>
        ))}
      </Grid>
    </>
  ) : emptyMessage ? (
    <Grid container className={classes.topSpacing}>
      <Grid item className={classes.topSpacing}>
        {t('noMetrics')}
      </Grid>
    </Grid>
  ) : null;
};

export default Metrics;
