import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TextField, ChipField, CloseButton, Confirmation } from '~/components';
import { Reference } from '~/types';
import { AsideBase } from '~/layout';
import palette from '~/theme/palette';
import ReferenceField from '~/components/ReferenceField';
import { hasTranslation, time } from '~/utils';

const useStyles = makeStyles(() => ({
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  },
}));

interface Props {
  reference: Reference;
  changelogMode?: boolean;
  active?: boolean;
  archive?: (id: string) => void;
  fullView?: boolean;
}

export const ReferenceCard: FC<Props> = (props) => {
  const { reference, changelogMode, active, archive, fullView } = props;

  const translate = useTranslate();
  // Path was separated to check if localization exists.
  const path = (key: string): string => `components.AffiliatedObjects.${key}`;
  const t = (key: string): string => translate(path(key));

  const classes = useStyles();

  return (
    <Grid container spacing={4} key={reference.id}>
      <Grid item xs={12}>
        <AsideBase className={changelogMode ? classes.changelogMode : undefined}>
          <Grid container>
            <Grid container item spacing={2}>
              <Grid item xs={reference.subObjectId ? 6 : 12}>
                <ReferenceField
                  record={{ id: reference.objectId, type: reference.objectType }}
                  withDefaultIcon
                />
              </Grid>
              {reference.subObjectId && reference.subObjectType && (
                <Grid item xs={6}>
                  <ReferenceField
                    record={{ id: reference.subObjectId, type: reference.subObjectType }}
                    withDefaultIcon
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField label={t('affiliationType')} icon={<></>}>
                  <ChipField
                    label={
                      hasTranslation(path(`${reference.referenceType}`))
                        ? t(`${reference.referenceType}`)
                        : reference.referenceType
                    }
                  />
                </TextField>
              </Grid>
              {fullView && (
                <Grid item xs={12}>
                  <TextField label={t('createdAt')} icon={<></>}>
                    {time(reference.createdAt).format('l LT')}
                  </TextField>
                </Grid>
              )}
              {fullView && !active && (
                <Grid item xs={12}>
                  <TextField label={t('deletedAt')} icon={<></>}>
                    {time(reference.deletedAt).format('l LT')}
                  </TextField>
                </Grid>
              )}
              {fullView && active && archive && (
                <Grid item xs={12}>
                  <Grid container justify='flex-end'>
                    <Confirmation
                      onConfirm={() => {
                        archive(reference.id);
                      }}
                    >
                      <CloseButton label={t('archive')} />
                    </Confirmation>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AsideBase>
      </Grid>
    </Grid>
  );
};

export default ReferenceCard;
