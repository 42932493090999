import TransactionsHistory from './TransactionsHistory';
import TransactionView from './TransactionView';
import TableBody from './TableBody';
import RunTransferAction from './RunTransferAction';
import Notes from './Notes';
import ExternalProfileLinks from './ExternalProfileLinks';
import * as ra from './ra';

export default {
  TransactionsHistory,
  TransactionView,
  TableBody,
  RunTransferAction,
  Notes,
  ExternalProfileLinks,
  ra,
  AppBar: {
    TEST: 'Test environment',
    DEMO: 'Demo environment',
    PRODUCTION: 'Production',
    licenseMissing: 'License is missing',
    licenseNotActive: 'License has expired',
    LinkToProfile: {
      INDIVIDUAL: 'Individual',
      BUSINESS: 'Business',
      EMPLOYEE: 'Business team member',
      PARTNER: 'Partner',
      DEAL: 'Deal',
      HEAD_TRANSACTION: 'Head transaction',
      EVENT: 'Event',
      API_IDENTITY: 'Authentication identity',
      REPORT: 'Report',
      INDIVIDUAL_UUID: 'Individual UUID',
      BUSINESS_UUID: 'Business UUID',
      EMPLOYEE_UUID: 'Business team member UUID',
      PARTNER_UUID: 'Partner UUID',
      DEAL_UUID: 'Deal UUID',
      HEAD_TRANSACTION_UUID: 'Head transaction UUID',
      EVENT_UUID: 'Event UUID',
      API_IDENTITY_UUID: 'Authentication identity UUID',
      REPORT_UUID: 'Report UUID',
      go: 'Go',
      openObject: 'Open object by UUID',
      incorrectLink: 'Please remove all spaces.',
    },
    licenseWillExpire: 'Your license will expire on %{date}.',
    licenseExpiredMessage:
      'Your license has expired on %{date}. The system works in limited mode. Please contact your supplier to obtain new license.',
    licenseMissingMessage:
      'License is missing. The system works in limited mode. Please contact your supplier to obtain the license.',
  },
  address: {
    _: 'Address',
    country: 'Country',
    addressLine: 'Address line',
    city: 'City',
    cityLocalized: 'City localized',
    postalCode: 'Postal code',
    region: 'Region',
    suburb: 'Suburb',
    building: 'Building',
    geolocation: 'Geolocation',
    type: 'Type',
    latitude: 'Latitude',
    longitude: 'Longitude',
    note: 'Note',
  },
  BackgroundTask: {
    downloadCSV: 'Download CSV',
    downloadExcel: 'Download Excel file',
    downloadPDF: 'Download PDF',
    invoice: 'Invoice',
    INITIAL: 'Initial',
    IN_PROGRESS: 'In progress',
    DONE: 'Done',
    FAILED: 'Failed',
    loading: 'Loading',
    downloadWillStart: 'Download will start automatically',
    downloadTheResult: 'You can download the result',
    recordsAndErrors: 'Records: %{records}, errors: %{errors}.',
    solutionId: 'Solution',
    file: 'File',
  },
  ProfileLimits: {
    _: 'Profile limits',
    ownProfileLimits: 'Own profile limits',
    empty: 'There are no profile-wide limits yet.',
    outOf: 'out of',
    viewOwn: 'View Own',
    activePackages: 'Active Limit Packages',
    closedPackages: 'Closed Limit Packages',
    shared: 'SHARED',
    owner: 'Owner',
  },
  BlockOrUnblockProfile: {
    attention: 'Attention!',
    alert: 'Please confirm the blocking of the profile and indicate the reason',
    reason: 'Reason',
    profileStatusUpdated: 'Profile status updated',
  },
  ApiIdentity: {
    identificationData: 'Identification data',
    name: 'Name',
    username: 'Username',
    email: 'Email',
    phone: 'Phone',
    status: 'Status',
    secretWord: 'Secret word',
    apiKey: 'Api Key',
    apiKeyPrefix: 'Api Key Prefix',
    createdAt: 'Created At',
    password: 'Password',
    reset: 'Reset',
    valueTrue: 'Defined',
    valueFalse: 'Not defined',
    clearSecretWordWarning: `Resetting the user's Secret Word will delete the existing Secret Word to confirm actions in the Wallet App. Be sure that the user identity check is passed correctly. The user will require to define a new Secret Word after successful login. Are you sure you want to continue?`,
    //
    activeIdentityLocks: 'Active identity locks',
    addLock: 'Add lock',
    startDatetime: 'Start datetime',
    endDatetime: 'End datetime',
    reason: 'Reason',
    actor: 'Actor',
    outOf: 'out of',
    viewIdentityLock: 'View identity lock',
    officer: 'Officer',
    addIdentityLock: 'Add identity lock',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    deleteLock: 'Delete lock',
    deleteIdentityLock: 'Delete identity lock',
    delete: 'Delete',
    reasonToRemoveLock: 'Reason to remove lock',
    system: 'System',
    timeLeft: 'Time left',
    wrongAttemptsTooltip: 'The number of attempts before the user access is locked permanently.',
    //
    authenticationMethods: 'Authentication methods',
    devices: 'Devices',
    staticPassword: 'Static password',
    login: 'Login',
    otpBySms: 'OTP by SMS',
    wrongAttempts: 'Wrong attempts',
    wrongResetAttempts: 'Wrong reset attempts',
    resetSecretWord: 'Reset secret word',
    resetAttemptsCounter: 'Clear attempts counter',
    clearResetAttemptsCounter: 'Clear reset attempts counter',
    resetAttemptsCounterWarning:
      "You are going to reset the unsuccessful authentication attempt counter. This will allow user to make new authentication attempts and potentially weaken the system's security. Please confirm the counter reset.",
    osName: 'OS name',
    osVersion: 'OS version',
    pushToken: 'Push token',
    enrolledAt: 'Enrolled at',
    deviceLabel: 'Device label',
    applicationId: 'Application ID',
    applicationIdForVendor: 'Application ID for vendor',
    noDevices: 'There are no devices yet.',
  },
  ImportButton: 'Import',
  MetricField: {
    outOf: 'out of',
  },
  OperationTypeChangelog: {
    INSERT: 'Created',
    UPDATE: 'Updated',
    DELETE: 'Deleted',
    IMPORT: 'Registered',
  },
  RecordTitle: {
    prev: 'prev',
    next: 'next',
    version: 'Version as of',
    warning: 'You are viewing an old version of this profile. ',
    current: 'current version',
    history: 'View history',
    view: 'View the ',
    lastUpdated: 'Last updated: %{date} %{time} by %{by}. ',
    loading: 'Loading...',
    createdAt: 'created at',
  },
  EditSourceName: {
    editNameAndAlias: 'Edit name & alias',
    editAccountNameAndAlias: 'Edit account name & alias',
    editAlias: 'Edit alias',
    editAccountAlias: 'Edit account alias',
    name: 'Name',
    alias: 'Alias',
  },
  Originator: {
    _: 'Originator',
  },
  LinkToAccount: {
    goToAccount: 'GO TO ACCOUNT',
  },
  AffiliatedObjects: {
    affiliatedObjects: 'Affiliated objects',
    noAffiliatedObjects: 'There are no Affiliated Objects.',
    addAffiliatedObject: 'Add Affiliated Object',
    viewAll: 'View all',
    viewAllAffiliatedObjects: 'View all Affiliated Objects',
    affiliationType: 'Affiliation type',
    createdAt: 'Created at',
    deletedAt: 'Archived at',
    archive: 'Archive',
    archivedAffiliatedObjects: 'Archived Affiliated Objects',
    objectType: 'Object type',
    subObjectType: 'Sub-object type',
    objectUUID: 'Object UUID',
    subObjectUUID: 'Sub-object UUID',
    SHARED_WITH: 'Shared with',
    INVITED_BY: 'Invited by',
    COMMISSION_PAY_TO: 'Commission pay to',
    COMMISSION_PAY_FOR: 'Commission pay for',
    CLONED_FROM: 'Copied from',
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
    EMPLOYEE: 'Team member',
    PARTNER: 'Partner',
    warning:
      'The object cannot be found using the provided UUID. Please enter a valid and existing UUID.',
  },
  AccountOwner: {
    owner: 'Owner',
  },
  SelectInput: {
    any: 'Any',
  },
  AddFilterButton: {
    label: 'Add filter',
  },
  ProfileTags: {
    profileTags: 'Profile tags',
    edit: 'Edit',
    noTagsYet: 'There are no profile tags yet.',
    noTagsToAdd: 'There are no profile tags to add.',
    addProfileTags: 'Add profile tags',
    editProfileTags: 'Edit profile tags',
    profileTagsTooltip:
      'Profile tags serve for profiling purposes, aiding in the classification of profiles and facilitating pricing table selection. Optionally, they can be populated into the transaction context, enabling "forked" fee application based on the transaction tag mechanism.',
  },
  EventTask: {
    eventTasks: 'Event tasks',
    action: 'Action',
    reason: 'Reason',
    taskType: 'Task type',
    taskState: 'Task state',
    manualAction: 'Manual action',
    runManualAction: 'Run manual action',
    empty: 'There are no event tasks yet.',
    run: 'Run',
    noAvailableActions: 'No available actions',
  },
  ApiIdentityLink: {
    apiIdentity: 'API Identity',
  },
  AccountWithErrorWarning: {
    attention: 'Attention!',
    alert: 'Please confirm the error reset action and submit your comment',
    comment: 'Comment',
    warning: 'Warning: Error found on this account',
    cancel: 'Cancel',
    confirm: 'Confirm',
    errorResetSuccess: 'Error is cleared successfully!',
  },
};
