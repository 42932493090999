export default {
  name: 'Business Team Member |||| Business Team Members',

  fields: {
    subheader: 'Id:  %{id}. Created:  %{date}  %{time}',
    reference: {
      create: 'Create team member',
      show: 'Go to team member',
    },
    accessCode: 'Access code',
    authority: {
      _: 'Authority',
      owner: 'Owner',
      executive: 'Executive',
      position: 'Position',
      employeeRole: {
        _: 'Role',
        BUSINESS_MANAGER: 'Manager',
        BUSINESS_CASHIER: 'Cashier',
        BUSINESS_ADMINISTRATOR: 'Administrator',
      },
    },
    document: {
      _: 'Document',
      type: 'Type',
      number: 'Number',
    },
    documentType: 'Document Type',
    documentNumber: 'Document Number',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    any: 'Any',
    //   name: 'Name',
    phoneNumber: 'Phone number',
    terminal: 'Terminal',
    reportsTo: 'Reports to',
    editTeamMember: 'Edit team member',
    addTeamMember: 'Add team member',
    linkedTerminals: 'Linked terminals',
    getAccessCode: 'Get access code',
    getAccessCodeFor: 'Get access code for',
    phoneSmsCode: 'Phone number to send access code',
    accessCodeMessage:
      'Please communicate code to the user. You will not be able to view it again.',
    metrics: {
      _: 'Metrics',
    },
    cashierAgentAt: 'Cashier/agent at',
    managesLocations: 'Manages locations',
    managesLocation: 'Manages location',
    andministratorOrManager:
      "You can't select both Administrator and Manager roles. Please choose only one.",
    viewDetails: 'View details',
    searchInProgress: 'Search is in progress...',
    noResultsFound: 'No results found',
    name: 'Name',
    withDeleted: 'With deleted',
    role: 'Role',
    businessId: 'Business ID',
    workLocationId: 'Location ID',
    terminalId: 'Terminal ID',
    tabs: {
      details: 'Details',
      transactions: 'Transactions',
      identity: 'Identity',
      events: 'Events',
    },
    profileDetails: 'Profile details',
    memberOf: 'Member of',
    position: 'Position',
    owner: 'Owner',
    executive: 'Executive',
    roles: 'Roles',
    linkedToTerminal: 'Linked to terminal',
    status: 'Status',
    solution: 'Solution',
    businessEntity: 'Business entity',
    createdAt: 'Created at',
    noMetrics: 'There are no Metrics.',
  },
};
